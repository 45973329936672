import { EvacuationAnalyticsVacancyHistoryList } from "@/@types/launch-batch-analytics";
import dayjs from "dayjs";

export function histogramByVacancyStatus(
    vacancyStatus: string,
    graphData: EvacuationAnalyticsVacancyHistoryList,
): number[] {
    const t = graphData.map((gd) =>
        gd.statusCountList
            .map((l) => (l.status === vacancyStatus ? l.count : undefined))
            .filter((f) => f !== undefined),
    );
    return t.map((v) => (v[0] === undefined ? 0 : v[0]));
}

export function convertToZeroHHMM(unixtime: number): number {
    const convertedDate = dayjs.unix(unixtime).format("YYYY-MM-DD");
    return dayjs(`${convertedDate} 00:00`).unix();
}

import { SeriesType, PairSeriesType } from "@/@types/launch-batch-analytics";
export function getMaxDataFromPairType(series: PairSeriesType) {
    return series.data.reduce((max, p) => (p[1] > max ? p[1] : max), series.data[0][1]);
}
export function getMaxYFromStackedBarGraph(series: SeriesType[]): number {
    const histogram = new Array(24);
    for (let i = 0; i < 24; ++i) {
        histogram[i] = 0;
    }

    const dataList = series.map((s) => s.data);
    if (histogram.length !== dataList[0].length) return 100;
    dataList.forEach((seriesData) => {
        seriesData.forEach((d, i) => (histogram[i] += d));
    });

    return histogram.reduce((max, shot) => (shot >= max ? shot : max));
}
