import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { reloadHtmlLang } from "./utils/i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};

    locales.keys().forEach((key: string) => {
        const matched = key.match(/([a-z0-9_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
            messages[locale.replace("_", "-")] = locales(key);
            const shortLocale = locale.split("_")[0];
            if (messages[shortLocale] === undefined) {
                messages[shortLocale] = locales(key);
            }
        }
    });
    return messages;
}

export let locale: string = (
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    "ja_JP"
)

    .replace("-", "_")
    .replace(/_.*/g, function (match) {
        return match.toUpperCase();
    });

// unify format to ISO xx_OO
locale = locale === "ja" ? "ja_JP" : locale;

const savedLang: string | null = localStorage.getItem("vacan/console/lang");
if (savedLang !== null) {
    locale = savedLang;
}
reloadHtmlLang();

export default new VueI18n({
    locale, // set from browser language
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ja_JP",
    messages: loadLocaleMessages(), // load from file
});

export const SUPPORT_LOCALE_LIST = ["ja_JP", "en_US", "zh_CN", "zh_TW", "ko_KR"] as const;
export type SupportLocale = (typeof SUPPORT_LOCALE_LIST)[number];

export const emptyI18nObject = () => {
    return {
        ja_JP: "",
        en_US: "",
        zh_CN: "",
        zh_TW: "",
        ko_KR: "",
    };
};
