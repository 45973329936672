import BatchVacancy, { BatchVacancyState, initialState as batchVacancyInitialState } from "./vacancy-module";
import BatchPublish, { BatchPublishState, initialState as batchPublishInitialState } from "./publish-module";
import BatchEvacuationDrill, {
    BatchEvacuationDrillState,
    initialState as batchEvacuationDrillInitialState,
} from "./evacuation-drill-module";
import BatchIrregularSchedules, {
    BatchIrregularSchedulesState,
    initialState as batchIrregularSchedulesInitialState,
} from "./irregular-schedules-module";

export type BatchState = {
    vacancy: BatchVacancyState;
    publish: BatchPublishState;
    evacuationDrill: BatchEvacuationDrillState;
    irregularSchedules: BatchIrregularSchedulesState;
};

export const initialState = (): BatchState => ({
    vacancy: batchVacancyInitialState(),
    publish: batchPublishInitialState(),
    evacuationDrill: batchEvacuationDrillInitialState(),
    irregularSchedules: batchIrregularSchedulesInitialState(),
});

export default {
    namespaced: true,
    modules: {
        vacancy: BatchVacancy,
        publish: BatchPublish,
        evacuationDrill: BatchEvacuationDrill,
        irregularSchedules: BatchIrregularSchedules,
    },
};
