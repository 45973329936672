import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { setAuthorizationHeader, retryRequestOnError, isNotExpiredAccessToken } from "./utility";
import { ExpiredAccessTokenError } from "@/api/datastore/expired-access-token.error";

const heavyHttp: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_CONSOLE_ADAPTER_API,
});

heavyHttp.interceptors.request.use(
    (config) => {
        if (!isNotExpiredAccessToken()) {
            throw new ExpiredAccessTokenError(config);
        }
        const authConfig = setAuthorizationHeader(config);
        config = {
            ...authConfig,
        };
        // for ie11
        if (config.method === "get") {
            const timestamp: number = new Date().getTime();
            config.params = { ...config.params, timestamp };
        }
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(
            error.response
                ? (error.response.data as Record<string, unknown>).errors
                : { message: "connection error", name: "" },
        );
    },
);

// Check accesstoken validity if not try to refresh tokens
heavyHttp.interceptors.response.use(async (response: AxiosResponse): Promise<AxiosResponse> => {
    return response;
}, retryRequestOnError(heavyHttp));

export default heavyHttp;
