/* Error monitoring **/
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
if (process.env.VUE_APP_MODE !== "development") {
    Sentry.init({
        Vue,
        dsn: "https://e6cd564e8ca5440ea0ad10ba084c35cf@o150304.ingest.sentry.io/5776801",
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.VUE_APP_MODE,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });
}
/** Error monitoring */
