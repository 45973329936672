/** Space 管理者ページ */

import { AxiosResponse, AxiosError } from "axios";
import $http from "./datastore/http_console";

import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";

export async function updateSpaceData(
    layoutId: number,
    spaceId: number,
    payload: ConsoleAdapterApi.SpaceOutline,
): Promise<number> {
    try {
        return $http
            .post(`/v2/user/layouts/${layoutId}/spaces/${spaceId}`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error: AxiosError) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

export async function addSpaceData(layoutId: number, payload: ConsoleAdapterApi.SpaceOutline): Promise<number> {
    try {
        return $http
            .post(`/v2/user/layouts/${layoutId}/spaces`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error: AxiosError) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

export async function deleteSpaceData(layoutId: number, spaceId: number): Promise<number> {
    try {
        return $http
            .delete(`/v2/user/layouts/${layoutId}/spaces/${spaceId}`)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error: AxiosError) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

export default {
    updateSpaceData,
    addSpaceData,
    deleteSpaceData,
};
