
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import ie from "@/utils/ie11";
import isBetween from "dayjs/plugin/isBetween";
import { envShortName } from "@/lib/env-helper";
import { setCookie, getCookie } from "@/lib/cookie";
import { isWcagMode } from "@/lib/wcag";
import dayjs from "dayjs";
dayjs.extend(isBetween);

export type Notification = { name: string; startDate: string; endDate: string };

export default Vue.extend({
  name: "app",

  metaInfo() {
    return {
      // global template
      titleTemplate:
        process.env.VUE_APP_MODE === "production"
          ? `%s | VACAN`
          : `[${envShortName(process.env.VUE_APP_MODE ?? "")}]` + `%s | VACAN`,
    };
  },

  components: {
    VNotification: () => import("@/components/v/notification.vue"),
    WcagDialog: () => import("@/components/vacan-components/organisms/wcag-mode-modal.vue"),
    NudgeMessage: () => import("@/components/vacan-components/molecules/nudge-message.vue"),
  },

  data() {
    return {
      notification: {
        ie11: { name: "ie", startDate: "2022-02-09T00:00:00+09:00", endDate: "2022-11-01T00:00:00+09:00" },
        // maintenance: { name: "maintenance", startDate: "2022-07-05T00:00:00", endDate: "2022-07-21T05:00:00" },
        maintenance: {
          name: "maintenance",
          startDate: "2022-07-01T00:00:00+09:00",
          endDate: "2022-07-21T02:00:00+09:00",
        },
      },
      isNormalMode: true,
      isContrastNudgeShown: true,
    };
  },

  computed: {
    ...mapState("menu", ["isWcagDialogShown"]),
    isIE11(): boolean {
      return ie.isIE11();
    },
  },

  mounted() {
    this.isNormalMode = !isWcagMode();
    this.isContrastNudgeShown = getCookie("color-mode") === undefined;
  },

  methods: {
    ...mapMutations("menu", ["setWcagDialogShown"]),

    isAvailableNotification(notification: Notification): boolean {
      const now = dayjs();
      return now.isBetween(notification.startDate, notification.endDate);
    },

    setWcagContract(isNormalMode: boolean) {
      this.setWcagDialogShown(false);
      this.setColorMode(isNormalMode);
    },

    setColorMode(isNormalMode: boolean) {
      if (!isNormalMode) {
        document.body.classList.add("wcag-mode-on");
      } else {
        document.body.classList.remove("wcag-mode-on");
      }

      this.isNormalMode = isNormalMode;
      setCookie("color-mode", isNormalMode ? "normal" : "wcag", 400);
    },

    closeContrastNudge() {
      this.isContrastNudgeShown = false;
    },

    clickContrastNudge() {
      this.isContrastNudgeShown = false;
      this.setWcagDialogShown(true);
    },
  },
});
