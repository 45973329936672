import { ActionTree, GetterTree, MutationTree } from "vuex";
import { AppState } from "..";
import { BatchIrregularSchedulePlace } from "@/@types/launch-batch-irregular-schedules";

export type BatchIrregularSchedulesState = {
    targetPlaceList: BatchIrregularSchedulePlace[];
};

export const initialState = (): BatchIrregularSchedulesState => ({
    targetPlaceList: [],
});

export const state: BatchIrregularSchedulesState = initialState();

export const mutations: MutationTree<BatchIrregularSchedulesState> = {
    setTargetPlaceList(state: BatchIrregularSchedulesState, payload: { targetPlaceList: BatchIrregularSchedulePlace[] }) {
        state.targetPlaceList = payload.targetPlaceList;
    },
    clearTargetPlaceList(state: BatchIrregularSchedulesState) {
        state.targetPlaceList = [];
    },
};

export const actions: ActionTree<BatchIrregularSchedulesState, AppState> = {};

export const getters: GetterTree<BatchIrregularSchedulesState, AppState> = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
