
// https://material.io/icons/
import Vue from "vue";
export default Vue.extend({
  name: "components-icon",
  props: {
    name: { type: String, required: true },
    size: {
      type: Number,
      required: false,
      default: 24,
      validator: (value: number) => [12, 14, 18, 20, 24, 32, 36, 48].indexOf(value) !== -1,
    },
    rotate: {
      type: Number,
      required: false,
      default: 0,
      validator: (value: number) => [0, 90, 180, 270].indexOf(value) !== -1,
    },
    animationOn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
