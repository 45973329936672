import Vue from "vue";
import "./sentry";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import Meta from "vue-meta";
import i18n from "@/i18n";
import "@/validator";
// css is global import here
import "./assets/vacan-global.scss";
// import vue-gtag
import "@/lib/gtag";
import filterCoupon from "@/filters/coupon";
import filterTimeFormatter from "@/filters/timeformatter";

Vue.use(Meta);
Vue.use(filterCoupon);
Vue.use(filterTimeFormatter);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
