import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";
import dayjs from "dayjs";

export class V2MigrationStatus {
    public readonly checkedUnixtime: number;
    public readonly forceUseConsoleV2: boolean;
    public readonly migrationEndUnixtime: number | undefined;

    constructor(checkedUnixtime: number, migrationInformation: ConsoleAdapterApi.ResponseGetMemberV2Migration) {
        this.checkedUnixtime = checkedUnixtime;
        this.forceUseConsoleV2 = migrationInformation.forceUseConsoleV2;
        this.migrationEndUnixtime = migrationInformation.migrationEndUnixtime;
    }

    // 1日以内にAPIの情報と同期されているかどうか
    public isCheckedRecently(): boolean {
        const checkThreshold = dayjs().subtract(1, "day");
        return dayjs.unix(this.checkedUnixtime).isAfter(checkThreshold);
    }

    public isInMigrationPeriod(): boolean {
        return (
            !this.forceUseConsoleV2 &&
            this.migrationEndUnixtime !== undefined &&
            this.migrationEndUnixtime > dayjs().unix()
        );
    }
}
