
import Vue from "vue";
export default Vue.extend({
  name: "components-dropdown-menu",
  props: {
    width: { type: Number, default: 150 },
    height: { type: Number, default: 150 },
    paddingTop: { type: Number, default: 8 },
  },
  methods: {
    hide(): void {
      this.$emit("hide");
    },
  },
});
