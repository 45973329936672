import Vue, { PluginObject } from "vue";
import dayjs from "dayjs";

class TimeFormatter {
    public static datetimeForDisplay(datetime: string): string {
        return dayjs(datetime).format("YYYY/M/D H:mm");
    }

    public static timeForDisplay(time: string): string {
        return dayjs(`0000-00-00 ${time}`).format("H:mm");
    }
}

const install = () => {
    Vue.filter("datetime", (datetime: string) => {
        return TimeFormatter.datetimeForDisplay(datetime);
    });

    Vue.filter("time", (time: string) => {
        return TimeFormatter.timeForDisplay(time);
    });
};

export default {
    install,
} as PluginObject<void>;
