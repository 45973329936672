
import Vue from "vue";
export default Vue.extend({
  name: "component-menu-language-list",
  methods: {
    rowClicked(language: "zh_TW" | "ja_JP") {
      this.$emit("row-clicked", language);
    },
  },
});
