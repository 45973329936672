import { ActionTree, GetterTree, MutationTree } from "vuex";
import { AppState } from "..";
import { PublishPlace } from "@/@types/launch-batch-publish";

export type BatchPublishState = {
    targetPlaceList: PublishPlace[];
};

export const initialState = (): BatchPublishState => ({
    targetPlaceList: [],
});

export const state: BatchPublishState = initialState();

export const mutations: MutationTree<BatchPublishState> = {
    setTargetPlaceList(state: BatchPublishState, payload: { targetPlaceList: PublishPlace[] }) {
        state.targetPlaceList = payload.targetPlaceList;
    },
    clearTargetPlaceList(state: BatchPublishState) {
        state.targetPlaceList = [];
    },
};

export const actions: ActionTree<BatchPublishState, AppState> = {};

export const getters: GetterTree<BatchPublishState, AppState> = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
