import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { setAuthorizationHeader } from "./utility";
import router from "@/router";
import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";

/**
 * NOTE: This axios instance with intercepters must be used for ONLY renewSession
 */

const http: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_CONSOLE_ADAPTER_API,
    timeout: 10 * 1000,
});

http.interceptors.request.use(setAuthorizationHeader, (error: any) => {
    return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
});

http.interceptors.response.use(
    (response: AxiosResponse<ConsoleAdapterApi.ResponsePostLogin>): AxiosResponse => {
        localStorage.setItem("session/tokens/accessToken", response.data.accessToken);
        localStorage.setItem("session/tokens/refreshToken", response.data.refreshToken);
        return response;
    },
    (error: AxiosError) => {
        localStorage.removeItem("session/tokens/accessToken");
        localStorage.removeItem("session/tokens/refreshToken");
        window.location.href = "/login";
        return Promise.reject(error);
    },
);

export default http;
