import { ActionTree, MutationTree, GetterTree } from "vuex";
import { AppState } from "@/store";

export type MenuState = {
    isShrink: boolean;
    isWcagDialogShown: boolean;
};

export const initialState = (): MenuState => ({
    isShrink: false,
    isWcagDialogShown: false,
});

export const state: MenuState = initialState();

export const mutations: MutationTree<MenuState> = {
    setIsShrink(state: MenuState, payload: boolean): void {
        state.isShrink = payload;
    },
    setWcagDialogShown(state: MenuState, payload: boolean): void {
        state.isWcagDialogShown = payload;
    },
};

export const actions: ActionTree<MenuState, AppState> = {};

export const getters: GetterTree<MenuState, AppState> = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
