import { AxiosError } from "axios";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import SpaceMgrApi from "../api/space-mgr.api";
import { SpaceState, SpaceCard } from "spaces-mgr";
import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";
import { AppState } from "@/store";
import { ActionContext } from "vuex";

export const initialState = (): SpaceState => ({
    placeId: 0,
    layoutId: 0,
    isSelected: false,
    mode: "normal",
    isShrink: false,
    selectedSpaceId: -1,
    selectedSpaceIndex: -1,
    multiSelectedSpaceIdList: [],
    spacesList: [] as SpaceCard[],
    layoutAttributesList: [] as ConsoleAdapterApi.LayoutAttribute[],
    showAlertWindow: {
        state: false,
        title: "",
        subtitle: "",
    },
    isLoading: false,
    isComposing: false,
});

export const state: SpaceState = initialState();

export const mutations: MutationTree<SpaceState> = {
    setIsComposing(state: SpaceState, newStatus: boolean): void {
        state.isComposing = newStatus;
    },
    setIsLoading(state: SpaceState, newStatus: boolean): void {
        state.isLoading = newStatus;
    },
    setIsShrink(state: SpaceState, payload: boolean): void {
        state.isShrink = payload;
    },
    setMultiSelectedSpaceIdList(state: SpaceState, list: number[]): void {
        state.multiSelectedSpaceIdList = list;
    },
    setMode(state: SpaceState, newMode: "normal" | "copy" | "paste"): void {
        state.mode = newMode;
    },
    setShowAlertWindow(
        state: SpaceState,
        payload: {
            state: boolean;
            title: string;
            subtitle: string;
        },
    ) {
        state.showAlertWindow = payload;
    },
    setIsSelected(state: SpaceState, selected: boolean): void {
        state.isSelected = selected;
    },
    setSelectedSpaceId(state: SpaceState, spaceId: number): void {
        state.selectedSpaceId = spaceId;
    },
    setSelectedSpaceIndex(state: SpaceState, itemId: number): void {
        state.selectedSpaceIndex = itemId;
    },
    setSpacesList(state: SpaceState, list: SpaceCard[]) {
        state.spacesList = list;
    },
    setLayoutAttributesList(state: SpaceState, list: ConsoleAdapterApi.LayoutAttribute[]) {
        state.layoutAttributesList = list;
    },
    setSpaceItem(state: SpaceState, payload: { spaceId: number; item: SpaceCard }): void {
        state.spacesList[state.selectedSpaceIndex] = payload.item;
    },
    clearMultiSelectedItemIdList(state: SpaceState) {
        state.multiSelectedSpaceIdList = [];
    },
};

export const actions: ActionTree<SpaceState, AppState> = {
    // 1. Places 一覧の返却
    getPlaces(): Promise<ConsoleAdapterApi.UserPlace[]> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceMgrApi.getPlaces()
                .then((response: ConsoleAdapterApi.UserPlace[]) => {
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
    // 2. Layouts 一覧の返却
    getLayoutsListByPlaceId(
        _: ActionContext<SpaceState, AppState>,
        placeId: number,
    ): Promise<ConsoleAdapterApi.UserPlaceLayout[]> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceMgrApi.getLayoutsListByPlaceId(placeId)
                .then((response: ConsoleAdapterApi.UserPlaceLayout[]) => {
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
    // 3. Attributes 一覧の返却
    getLayoutAttributesListByLayoutId(
        _: ActionContext<SpaceState, AppState>,
        layoutId: number,
    ): Promise<ConsoleAdapterApi.LayoutAttribute[]> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceMgrApi.getLayoutAttributesListByLayoutId(layoutId)
                .then((response: ConsoleAdapterApi.LayoutAttribute[]) => {
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
    // 4. Space空き状況の選択肢一覧の返却
    getSpaceVacancyStatusesBySpaceId(
        _: ActionContext<SpaceState, AppState>,
        spaceId: number,
    ): Promise<ConsoleAdapterApi.SpaceVacancyStatus[]> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceMgrApi.getSpaceVacancyStatusesBySpaceId(spaceId)
                .then((response: ConsoleAdapterApi.SpaceVacancyStatus[]) => {
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
    // 5. Space Outline 情報一覧の返却
    getSpaceOutlineListByLayoutId(
        _: ActionContext<SpaceState, AppState>,
        layoutId: number,
    ): Promise<ConsoleAdapterApi.SpaceOutline[]> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceMgrApi.getSpaceOutlineListByLayoutId(layoutId)
                .then((response: ConsoleAdapterApi.SpaceOutline[]) => {
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
    // 6. 空き状況と顧客情報の返却 in a loop (５秒更新し)
    getSpaceCustomerVacancyData(
        _: ActionContext<SpaceState, AppState>,
        payload: { layoutId: number; spaceIdList: number[] },
    ): Promise<ConsoleAdapterApi.SpaceCustomerVacancyData[]> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceMgrApi.getSpaceCustomerVacancyData(payload.layoutId, payload.spaceIdList)
                .then((response: ConsoleAdapterApi.SpaceCustomerVacancyData[]) => {
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
    // 7. 空き状況と顧客情報の更新
    updateSpaceCustomerData(
        _: ActionContext<SpaceState, AppState>,
        payload: {
            layoutId: number;
            data: ConsoleAdapterApi.UpdateSpaceCustomerVacancyData[];
        },
    ): Promise<number> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceMgrApi.updateSpaceCustomerData(payload.layoutId, payload.data)
                .then((statusCode: number) => {
                    resolve(statusCode);
                })
                .catch((error: AxiosError) => {
                    reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
};

/** @remarks
 * Use getter to get state data rather than direct access */
export const getters: GetterTree<SpaceState, AppState> = {
    findAttributeValueByDisplayOrder(state: SpaceState) {
        return (t_displayOrder: number, customerAttributes: ConsoleAdapterApi.CustomerData[]): string => {
            const targetAttribute: ConsoleAdapterApi.LayoutAttribute | undefined = state.layoutAttributesList.find(
                (attribute: ConsoleAdapterApi.LayoutAttribute) => attribute.displayOrder === t_displayOrder,
            );

            if (targetAttribute === undefined) return "";

            const selectedAttribute: ConsoleAdapterApi.CustomerData | undefined = customerAttributes.find(
                (item: ConsoleAdapterApi.CustomerData) => item.attributeId === targetAttribute.attributeId,
            );
            return selectedAttribute !== undefined ? selectedAttribute.value : "";
        };
    },
    findAttributeValueByAttributeId(state: SpaceState) {
        return (t_attributeId: number, customerAttributes: ConsoleAdapterApi.CustomerData[]): string => {
            const targetAttribute: ConsoleAdapterApi.LayoutAttribute | undefined = state.layoutAttributesList.find(
                (attribute: ConsoleAdapterApi.LayoutAttribute) => attribute.attributeId === t_attributeId,
            );

            if (targetAttribute === undefined) return "";

            const selectedAttribute: ConsoleAdapterApi.CustomerData | undefined = customerAttributes.find(
                (item: ConsoleAdapterApi.CustomerData) => item.attributeId === targetAttribute.attributeId,
            );
            return selectedAttribute !== undefined ? selectedAttribute.value : "";
        };
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
