export function envShortName(env: string) {
    if (env.includes("qa")) {
        return "qa";
    } else if (env === "development") {
        return "dev";
    } else if (env === "staging") {
        return "stg";
    } else return "";
}

export function envBarText(env?: string): string {
    if (!env) return "";
    if (env.includes("qa")) return "QA environment / QA環境";
    else if (env === "development") return "DEVELOPMENT / 開発環境";
    else if (env === "staging") return "STAGING / 検証環境";
    else return "";
}

export function faviconHref(env: string) {
    if (env.includes("qa")) {
        return "/favicon-qa.ico";
    } else {
        switch (env) {
            case "development":
                return "/favicon-dev.ico";
            case "staging":
                return "/favicon-stg.ico";
            case "production":
            default:
                return "/favicon.ico";
        }
    }
}
