import { RequestFetchEvacuationDrill } from "./../@types/launch-batch-evacuation-drill.d";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";
import { locale } from "@/i18n";
import { getLocale } from "@/utils/i18n";
import http from "@/api/datastore/http_console";
import httpWithoutToken from "@/api/datastore/http_console-without-token";
import httpForRenewSession from "@/api/datastore/http_renew_session";
import httpForHeavyRequest from "@/api/datastore/http_for_heavy_request";
import { convertToZeroHHMM } from "@/utils/graph-tool";
import { AggregateResponseBase, GraphicUnit } from "@/@types/dashboard";
import { wcagParam } from "@/lib/wcag";
/* ######################################################################
start: Do NOT use intercepter about tokens for user.api related endpoints
###################################################################### */
// user
export function signup({ email }: { email: string }): Promise<number> {
    return httpWithoutToken
        .post(`/v1/user/signup`, { email })
        .then((response: AxiosResponse) => response.status as number)
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function signin({
    email,
    password,
}: ConsoleAdapterApi.RequestPostLogin): Promise<ConsoleAdapterApi.ResponsePostLogin> {
    return httpWithoutToken
        .post(`/v1/user/signin`, { email, password })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function signout(): Promise<boolean> {
    return httpWithoutToken
        .post(`/v1/user/signout`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("session/tokens/accessToken") || ""}` },
        })
        .then((response: AxiosResponse) => response.status === 200 || response.status === 401)
        .catch((error) => {
            return error.response?.status === 400
                ? Promise.resolve(true)
                : Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function renewSession(): Promise<ConsoleAdapterApi.ResponsePostLogin> {
    // SPECIAL axios instance for renewSession
    return httpForRenewSession
        .post(`/v1/user/session`, { refreshToken: localStorage.getItem("session/tokens/refreshToken") })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function resetPassword({
    email,
    newPassword,
    confirmationCode,
}: ConsoleAdapterApi.RequestPostResetPassword): Promise<ConsoleAdapterApi.ResponsePostLogin> {
    return httpWithoutToken
        .post(`/v1/user/reset-password`, { email, newPassword, confirmationCode })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function forgotPassword({ email }: ConsoleAdapterApi.RequestPostForgotPassword): Promise<number> {
    return httpWithoutToken
        .post(`/v1/user/forgot-password`, { email })
        .then((response: AxiosResponse) => response.status)
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

/* ######################################################################
end
###################################################################### */

// sidemenu
export function fetchMenuList(): Promise<ConsoleAdapterApi.ResponseGetAuthorizedMenu[]> {
    return http
        .get<ConsoleAdapterApi.ResponseGetAuthorizedMenu[]>(`/v1/menus`, { params: { language: locale } })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function fetchAuthorizationHash(): Promise<ConsoleAdapterApi.ResponseConsoleAuthorization> {
    return http
        .get<ConsoleAdapterApi.ResponseConsoleAuthorization>("/v1/user/console/authorization", {
            headers: { Authorization: `Bearer ${localStorage.getItem("session/tokens/accessToken") || ""}` },
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

// authorized places
export function fetchAuthorizedPlaceList({
    searchPlaceName,
    placeIdHashList = [],
    limit,
    aisPage = false, // for AIS検知ページのみ
}: {
    searchPlaceName?: string;
    placeIdHashList?: string[];
    limit?: number;
    aisPage?: boolean;
}): Promise<ConsoleAdapterApi.ResponseGetUserPlaceHeader[]> {
    return http
        .get<ConsoleAdapterApi.ResponseGetUserPlaceHeader[]>(`/v1/user/places/header`, {
            params: {
                searchPlaceName: searchPlaceName === undefined ? undefined : searchPlaceName,
                placeIdHashList: placeIdHashList.length <= 0 ? undefined : placeIdHashList.join(","),
                limit,
                aisPage,
            },
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data : { message: "connection error", name: "", code: error.code },
            );
        });
}

// names
export function fetchPlaceInfo(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseGetNameAndAddress> {
    return http
        .get(`/v1/places/${placeIdHash}/name`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceInfo(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPutNameAndAddress,
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/name`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// schedules
export function fetchPlaceSchedule(placeIdHash: string): Promise<ConsoleAdapterApi.ResponsePlaceSchedule> {
    const params: { unixtime: number } = {
        unixtime: dayjs().unix(),
    };

    return http
        .get(`/v1/user/places/${placeIdHash}/schedules`, { params })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceSchedule(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPlaceSchedule,
): Promise<number> {
    try {
        return http
            .put(`/v1/user/places/${placeIdHash}/schedules`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// irregular-schedules

export function fetchIrregularScheduleList(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponsePlaceIrregularSchedule> {
    try {
        const params: { fromDate: string; unixtime: number } = {
            fromDate: dayjs().format("YYYY-MM-DD"),
            unixtime: dayjs().unix(),
        };

        return http
            .get(`/v1/user/places/${placeIdHash}/irregular-schedules`, { params })
            .then((response: AxiosResponse) => response.data)
            .catch((error) =>
                Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
            );
    } catch {
        return Promise.reject(400);
    }
}

export function saveIrregularSchedule(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPlaceIrregularSchedule,
): Promise<number> {
    try {
        return http
            .post(`/v1/user/places/${placeIdHash}/irregular-schedules`, payload)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

export function deleteIrregularSchedule(placeIdHash: string, date: string): Promise<number> {
    try {
        return http
            .delete(`/v1/user/places/${placeIdHash}/irregular-schedules/date/${date}`)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

// categories
export function fetchChildPlaceCategoriesListByRoot(
    baseCategoryType: string,
    language = "ja-JP",
): Promise<ConsoleAdapterApi.ResponseGetPlaceCategory[]> {
    return http
        .get(`/v1/place-categories/base-category-type/${baseCategoryType}`, { params: { language } })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function fetchRootPlaceCategoryByPlaceIdHash(
    placeIdHash: string,
    language = "ja-JP",
): Promise<ConsoleAdapterApi.ResponseGetPlaceCategory> {
    return http
        .get(`/v1/places/${placeIdHash}/root-place-category`, { params: { language } })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function fetchChildPlaceCategoriesByPlaceId(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetPlaceCategory[]> {
    return http
        .get(`/v1/places/${placeIdHash}/place-categories`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function fetchRootPlaceCategoriesByPlaceIdHash(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetPlaceCategory> {
    return http
        .get(`/v1/places/${placeIdHash}/root-place-category`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceCategories(
    placeIdHash: string,
    payload: {
        placeCategoryTypeList: string[];
    },
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/place-categories`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// equipments
export function fetchPlaceDescriptionEquipment(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponsePlaceRestaurantEquipment> {
    return http
        .get(`/v1/user/places/${placeIdHash}/equipments/restaurant`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceDescriptionEquipment(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPlaceRestaurantEquipment,
): Promise<number> {
    try {
        return http
            .put(`/v1/user/places/${placeIdHash}/equipments/restaurant`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// toilets
export function fetchPlaceAttachmentToilet(
    placeIdHash: string,
    language = "ja_JP",
): Promise<ConsoleAdapterApi.ResponsePlaceToiletEquipment> {
    return http
        .get(`/v1/user/places/${placeIdHash}/equipments/toilet`, { params: { language } })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceAttachmentToilet(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPlaceToiletEquipment,
): Promise<number> {
    try {
        return http
            .put(`/v1/user/places/${placeIdHash}/equipments/toilet`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// accesses
export function fetchPlaceDescriptionAccess(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetDescriptionAccess> {
    return http
        .get(`/v1/places/${placeIdHash}/description/access`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceDescriptionAccess(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPutDescriptionAccess,
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/description/access`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// remarks
export function fetchPlaceDescriptionRemark(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetDescriptionRemark> {
    return http
        .get(`/v1/places/${placeIdHash}/description/remark`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceDescriptionRemark(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPutDescriptionRemark,
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/description/remark`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// payments
export function fetchPlaceDescriptionPayment(
    placeIdHash: string,
    language = "ja_JP",
): Promise<ConsoleAdapterApi.ResponseGetPayments> {
    return http
        .get(`/v1/places/${placeIdHash}/description/payments`, { params: { language } })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceDescriptionPayment(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPayments,
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/description/payments`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// menu
export function fetchPlaceDescriptionMenu(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseGetDescriptionMenu> {
    const timestamp = new Date().getTime() / 1000;
    return http
        .get(`/v1/places/${placeIdHash}/description/menu`, { params: { timestamp } })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceDescriptionMenu(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPutDescriptionMenu,
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/description/menu`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// medias
export function fetchPlaceDescriptionMedia(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetDescriptionMedia> {
    return http
        .get(`/v1/places/${placeIdHash}/description/media`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" });
        });
}

export function updatePlaceDescriptionMedia(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPutDescriptionMedia,
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/description/media`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

// iot button settings
export function fetchVacancyStatusButtonSetting(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetVacancyStatusButton> {
    try {
        return http
            .get(`/v1/places/${placeIdHash}/settings/vacancy-status-button`)
            .then((response: AxiosResponse) => {
                if (response.status === 204) {
                    return undefined;
                }
                return response.data;
            })
            .catch((error) =>
                Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
            );
    } catch {
        return Promise.reject(400);
    }
}

export function updateVacancyStatusButtonSetting(placeIdHash: string, payload: object) {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/settings/vacancy-status-button`, payload)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

// default vacancy settings

export function fetchDefaultVacancySetting(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetVacancyDefaultSetting | undefined> {
    try {
        return http
            .get<ConsoleAdapterApi.ResponseGetVacancyDefaultSetting>(
                `/v1/places/${placeIdHash}/vacancies/settings/default`,
            )
            .then((response: AxiosResponse) => {
                if (response.status === 204) {
                    return undefined;
                }
                return response.data;
            })
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

export function updateDefaultVacancySetting(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPutVacancyDefaultSetting,
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/vacancies/settings/default`, payload)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

// facility settings
export function fetchFacility(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseGetFacilityV2 | undefined> {
    return http
        .get<{
            rootFacilityId: number;
            isActive: boolean;
        }>(`/v2/places/${placeIdHash}/facilities`)
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return undefined;
            }
            return response.data as ConsoleAdapterApi.ResponseGetFacilityV2;
        })
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

export async function postFacilityActivation(placeIdHash: string, isActive: boolean): Promise<void> {
    const payload: { isActive: boolean } = { isActive };

    await http
        .post(`/v1/places/${placeIdHash}/facilities/activate`, payload)
        .then((response: AxiosResponse) => response.status)
        .catch((error) => Promise.reject(error.response ? error.response.status : 500));
}

export function fetchFacilitySettings(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetFacilitySetting | undefined> {
    return http
        .get<ConsoleAdapterApi.ResponseGetFacilitySetting>(`/v1/places/${placeIdHash}/facilities/setting`)
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return undefined;
            }
            return response.data as ConsoleAdapterApi.ResponseGetFacilitySetting;
        })
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

export function putFacilitySetting(
    placeIdHash: string,
    request: ConsoleAdapterApi.RequestPutFacilitySetting,
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/facilities/setting`, request)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export async function fetchBatteryLevelHealth(
    placeIdHash: string,
    deviceType: string,
): Promise<ConsoleAdapterApi.ResponseGetBatteryLevelHealth[]> {
    const params: { deviceType: string } = { deviceType };
    return http
        .get(`/v1/places/${placeIdHash}/facilities/battery-level`, { params })
        .then((response: AxiosResponse) => {
            return response.data as ConsoleAdapterApi.ResponseGetBatteryLevelHealth[];
        })
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

export async function patchSensorsMemo(body: ConsoleAdapterApi.RequestPatchSensorMemo[]): Promise<void> {
    await http
        .patch(`/v1/sensors/memo`, body)
        .then()
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

export async function putInOutCountClear({
    placeIdHash,
    body,
}: {
    placeIdHash: string;
    body: ConsoleAdapterApi.RequestPutClearInOutCount;
}): Promise<void> {
    await http
        .put(`/v1/places/${placeIdHash}/facility/in-out-count/clear`, body)
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

export function fetchInOutCountClearSetting(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetInOutCountClearSetting | undefined> {
    return http
        .get(`/v1/places/${placeIdHash}/facility/in-out-count/settings/clear`)
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return undefined;
            }

            return response.data as ConsoleAdapterApi.ResponseGetInOutCountClearSetting;
        });
}

export async function putInOutCountClearSetting(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPutInOutCountClearSetting,
): Promise<void> {
    const data: ConsoleAdapterApi.RequestPutInOutCountClearSetting = {
        settingType: payload.settingType,
        reservedTimeList: payload.settingType === "manual" ? payload.reservedTimeList : undefined,
        clearBufferMinutes: payload.settingType === "openingHours" ? payload.clearBufferMinutes : undefined,
    };

    await http
        .put(`/v1/places/${placeIdHash}/facility/in-out-count/settings/clear`, data)
        .then((response: AxiosResponse) => response.status);
}

// images

export function fetchImageSummary(
    placeIdHash: string,
    namespace: string,
): Promise<ConsoleAdapterApi.ResponseGetPlaceImages> {
    return http
        .get(`/v1/places/${placeIdHash}/images`, { params: { namespace } })
        .then((response: AxiosResponse) => response.data)
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

export function fetchImageLibrary(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseGetPlaceImageLibrary> {
    return http
        .get(`/v1/places/${placeIdHash}/images/library`)
        .then((response: AxiosResponse) => response.data)
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

export function uploadImageIntoLibrary(
    placeIdHash: string,
    file: { file: Blob; name: string; width: number; height: number; size: number },
): Promise<ConsoleAdapterApi.ResponsePostPlaceImageLibrary> {
    const data: FormData = new FormData();
    data.append("image", file.file, file.name);
    data.append("width", file.width.toString());
    data.append("height", file.height.toString());
    data.append("size", file.size.toString());
    return http
        .post(`/v1/places/${placeIdHash}/images/library`, data)
        .then((response: AxiosResponse) => response.data)
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

export function deleteImageInLibrary(
    placeIdHash: string,
    imageIdHash: string,
): Promise<ConsoleAdapterApi.ResponsePostPlaceImageLibrary> {
    return http
        .delete(`/v1/places/${placeIdHash}/images/${imageIdHash}`)
        .then((response: AxiosResponse) => response.data)
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

export function fetchImageIdListInGarally(
    placeIdHash: string,
    namespace: string,
): Promise<ConsoleAdapterApi.ResponseGetSubImageIdHashList> {
    return http
        .get(`/v1/places/${placeIdHash}/images/garally/idlist`, { params: { namespace } })
        .then((response: AxiosResponse) => response.data)
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

export function replaceAllImageListInGarally(
    placeIdHash: string,
    namespace: string,
    imageIdWidhDisplayOrderList: { imageIdHash: string; displayOrder: number | null }[],
): Promise<ConsoleAdapterApi.ResponseGetSubImageIdHashList> {
    return http
        .post(`/v1/places/${placeIdHash}/images/garally`, imageIdWidhDisplayOrderList, { params: { namespace } })
        .then((response: AxiosResponse) => response.data)
        .catch((error) =>
            Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
        );
}

// manual input

export function fetchCurrentVacancy(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseGetVacancy> {
    try {
        return http
            .get(`/v1/places/${placeIdHash}/vacancy`, {
                params: {
                    language: locale,
                    ...wcagParam(),
                },
            })
            .then((response: AxiosResponse) => response.data)
            .catch((error) =>
                Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
            );
    } catch {
        return Promise.reject(400);
    }
}

export function fetchCurrentRawVacancy(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseGetRawVacancy> {
    try {
        return http
            .get(`/v1/places/${placeIdHash}/raw-vacancies`)
            .then((response: AxiosResponse) => response.data)
            .catch((error) =>
                Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
            );
    } catch {
        return Promise.reject(400);
    }
}

export function fetchSelectableVacancyStatusList(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseGetSelectableVacancyStatus[]> {
    try {
        return http
            .get(`/v1/places/${placeIdHash}/vacancy/statuses`, {
                params: {
                    language: locale,
                    ...wcagParam(),
                },
            })
            .then((response: AxiosResponse) => response.data)
            .catch((error) =>
                Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
            );
    } catch {
        return Promise.reject(400);
    }
}

export function overrideVacancyByManualInput(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPostVacancy,
): Promise<number> {
    try {
        return http
            .post(`/v1/places/${placeIdHash}/vacancy`, payload)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

//coupon

export function fetchCouponList(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseGetCoupon[]> {
    try {
        return http
            .get(`/v1/places/${placeIdHash}/coupons`)
            .then((response: AxiosResponse) => response.data)
            .catch((error) =>
                Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
            );
    } catch {
        return Promise.reject(400);
    }
}

export function saveCoupon(placeIdHash: string, payload: ConsoleAdapterApi.RequestPostCoupon): Promise<number> {
    try {
        return http
            .post(`/v1/places/${placeIdHash}/coupons`, payload)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

export function deleteCoupon(placeIdHash: string, couponIdHash: string): Promise<number> {
    try {
        return http
            .delete(`/v1/places/${placeIdHash}/coupons/${couponIdHash}`)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

export function fetchGenderSwitchingSetting(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseGenderSetting> {
    try {
        return http
            .get(`/v1/places/${placeIdHash}/hot-spring-gender/settings`)
            .then((response: AxiosResponse) => response.data)
            .catch((error) =>
                Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" }),
            );
    } catch {
        return Promise.reject(400);
    }
}

export function updateGenderSwitchingSetting(
    placeIdHash: string,
    requestGenderSetting: ConsoleAdapterApi.RequestGenderSetting,
): Promise<number> {
    try {
        return http
            .put(`/v1/places/${placeIdHash}/hot-spring-gender/settings`, requestGenderSetting)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
/** @remarks dashboard page */
export function getDashboradData(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseConsoleTop | undefined> {
    try {
        return http
            .get(`/v1/place/${placeIdHash}/console-top`)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => Promise.reject(error.message ? error : { message: "connection error", name: "" }));
    } catch {
        return Promise.reject(400);
    }
}

export function fetchPlaceTimeZone(placeIdHash: string): Promise<ConsoleAdapterApi.TimeZone> {
    try {
        return http
            .get(`/v1/places/${placeIdHash}/timezone`)
            .then((response: AxiosResponse) => response.data)
            .catch((error) =>
                Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" }),
            );
    } catch {
        return Promise.reject(400);
    }
}

/** @remarks launch page
 * 複数店舗施設を表示と変更する機能
 */
import { LaunchAvailability } from "@/@types/launch-batch-publish";
import { ResponseGetPlaceVacancy, ResponseBatchManualInputPlaceSearchSummary } from "@/@types/launch";
import { RequestFetchVacancyPlaces } from "@/@types/launch-batch-vacancy";

export function fetchLaunchAvailability(): Promise<LaunchAvailability> {
    try {
        return http
            .get(`/v1/user/launch/availability`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export function fetchLaunchList(page = 1, limit = 100): Promise<ConsoleAdapterApi.ResponseLaunchPlace> {
    try {
        return http
            .get(`/v1/user/launch/places`, { params: { language: locale, page, limit } })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export function fetchLaunchBatchVacancyList(
    page = 1,
    limit = 100,
    params: RequestFetchVacancyPlaces,
): Promise<ResponseBatchManualInputPlaceSearchSummary> {
    const rootPlaceCategoryType =
        params.rootPlaceCategoryType === undefined || params.rootPlaceCategoryType === ""
            ? "evacuation-center,polling-place"
            : params.rootPlaceCategoryType;
    try {
        return http
            .get(`/v1/user/batch-editing/manual-input/places/root-place-category-type/${rootPlaceCategoryType}`, {
                params: { limit, page, nameText: params.nameText, addressText: params.addressText },
            })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export function fetchVacancyStatusListByPlaceIdHashList(placeIdHashList: string[]): Promise<ResponseGetPlaceVacancy[]> {
    try {
        return http
            .get(`/v1/batch-editing/places/${placeIdHashList.join(",")}/vacancy`, {
                params: {
                    language: locale,
                    ...wcagParam(),
                },
            })
            .then((response: AxiosResponse) => response.data)
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
// 一括空き状態編修ページのcongestionボタン
export function fetchVacancyButtonListByPlaceIdHashList(
    placeIdHashList: string[],
): Promise<ConsoleAdapterApi.ResponseGetSelectableVacancyStatus[]> {
    try {
        return http
            .get(`/v1/batch-editing/places/${placeIdHashList}/vacancy/statuses`, { params: { language: locale } })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export function publishVacancyByPlaces(
    placeIdHashList: string[],
    payload: {
        status: ConsoleAdapterApi.VacancyStatus;
        waitingSeconds: number;
        durationInMinutes: number;
    },
): Promise<number> {
    try {
        return http
            .post(`/v1/batch-editing/places/${placeIdHashList}/vacancy`, payload)
            .then((response: AxiosResponse) => response.status)
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

/** batch publish places on Maps
 * 一括地図への公開/非公開
 * */
import {
    RequestFetchPublishPlaces,
    PlaceCategory,
    RequestPublicSchedule,
    ResponsePublishPlaceSearchSummary,
} from "@/@types/launch-batch-publish";
//
export function fetchLaunchBatchPublishList(
    page = 1,
    limit = 50,
    filterParams?: RequestFetchPublishPlaces,
): Promise<ResponsePublishPlaceSearchSummary> {
    try {
        return http
            .get(`/v1/user/batch-editing/maps/places/publish`, {
                params: { limit, page, ...filterParams },
            })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
export function batchUpdatePublishInfo(
    placeIdHashList: string[],
    payload: RequestPublicSchedule,
    publicStatus: string,
): Promise<number> {
    try {
        const publishEndpoint = publicStatus === "public" ? "publish" : "unpublish";
        return http
            .put(`/v1/user/batch-editing/maps/places/${placeIdHashList.join(",")}/${publishEndpoint}`, payload)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
export function fetchGenreList(): Promise<PlaceCategory[]> {
    try {
        return http
            .get(`/v1/place-categories/root-categories`, { params: { language: locale } })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
/** batch irregular schedules
 * 一括臨時休業＆営業
 **/
import {
    BatchIrregularSchedulePlaceSearchSummary,
    ResponseGetPlaceCategoryI18n,
    RequestIrregularScheduleItem,
    RequestDeletePlaceIrregularSchedule,
    ResponseBatchPlaceIrregularSchedule,
} from "@/@types/launch-batch-irregular-schedules";
export function fetchLaunchBatchIrregularSchedulesList(
    page = 1,
    limit = 50,
    filterParams?: RequestFetchPublishPlaces,
): Promise<BatchIrregularSchedulePlaceSearchSummary> {
    // to show data after today
    const fromDate: string = dayjs().format("YYYY-MM-DD");
    try {
        return http
            .get(`/v1/search/console/places/batch-irregular-schedule`, {
                params: { limit, page, ...filterParams, fromDate },
            })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
// https://vacancorp.kibe.la/notes/33486#ジャンルを取得するエンドポイント
// batch 空き入力 投票所避難所専用の endpoint
// TODO: 既存のendpointを合併する方がいい
export function fetchBatchManualInputI18nGenreList(): Promise<ResponseGetPlaceCategoryI18n[]> {
    try {
        return http
            .get(`/v1/user/batch-editing/manual-input/root-place-categories`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
// ↓ rootのplaceCategoryのこと
export function fetchI18nGenreList(pageName?: string): Promise<ResponseGetPlaceCategoryI18n[]> {
    try {
        return http
            .get(`/v2/place-categories/root-categories`, { params: { pageName } })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
// ↓ rootじゃないplaceCategoryのこと
export function fetchI18nGenreCategoryList(baseCategoryType: string): Promise<ResponseGetPlaceCategoryI18n[]> {
    try {
        return http
            .get(`/v2/place-categories/base-category-type/${baseCategoryType}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
export function batchSaveIrregularSchedule(
    placeIdHashList: string[],
    scheduleList: RequestIrregularScheduleItem[],
): Promise<number> {
    try {
        return http
            .post(`/v1/user/places/${placeIdHashList.join(",")}/irregular-schedules`, { scheduleList })
            .then((response: AxiosResponse) => response.status)
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}
export function batchDeleteIrregularSchedule(list: RequestDeletePlaceIrregularSchedule[]): Promise<number> {
    try {
        return http
            .delete(`/v1/places/irregular-schedules`, { data: [...list] })
            .then((response: AxiosResponse) => response.status)
            .catch((error) => Promise.reject(error.response ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}
export function batchFetchIrregularScheduleList(
    placeIdHashList: string[],
): Promise<ResponseBatchPlaceIrregularSchedule[]> {
    try {
        const params: { fromDate: string; unixtime: number } = {
            fromDate: dayjs().format("YYYY-MM-DD"),
            unixtime: dayjs().unix(),
        };

        return http
            .get(`/v1/user/places/irregular-schedules`, {
                params: {
                    placeIdHashList: placeIdHashList.join(","),
                    fromDate: params.fromDate,
                },
            })
            .then((response: AxiosResponse) => response.data)
            .catch((error) =>
                Promise.reject(error.response ? error.response.data : { message: "connection error", name: "" }),
            );
    } catch {
        return Promise.reject(400);
    }
}

/**
 * Auto keep
 **/
import { SpaceLogArgs } from "@/entities/space_log";

export type AutokeepQueue = {
    count?: number;
};

export type AutokeepQueueMap = Record<string, AutokeepQueue[]>;

export function fetchAutokeepQueues(placeIdHash: string): Promise<AutokeepQueueMap> {
    try {
        return http
            .get(`/v1/autokeep/queues/${placeIdHash}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export type AutokeepSpaceLogList = {
    current_page: number;
    pages: number;
    logs: SpaceLogArgs[]; // FIXME: maybe type mismatch between backend and here
};

export function fetchAutokeepSpaceLogs(placeIdHash: string, status: string): Promise<AutokeepSpaceLogList> {
    try {
        return http
            .get(`/v1/autokeep/space-logs/${placeIdHash}?status=${status}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
/* eslint-disable-next-line */
export function fetchAutokeepSalesByDay(placeIdHash: string, year: number, month: number): Promise<any> {
    try {
        return http
            .get(`/v1/autokeep/sales-by-day/${placeIdHash}/${year}/${month}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
/* eslint-disable-next-line */
export function fetchAutokeepClaims(placeIdHash: string): Promise<any> {
    try {
        return http
            .get(`/v1/autokeep/claims/${placeIdHash}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export async function postAutokeepEventImageUpdated(placeIdHash: string): Promise<void> {
    await http
        .post(`/v1/autokeep/event/image-updated/${placeIdHash}`)
        .then()
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

export interface AutoKeepAnnouncement {
    id: string;
    title: string;
    body: string;
    createdAt: number;
    updatedAt: number;
}

export function getAutokeepAnnouncements(placeIdHash: string): Promise<AutoKeepAnnouncement[]> {
    try {
        return http
            .get(`/v1/autokeep/announcements/${placeIdHash}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
/* eslint-disable-next-line */
export function getAutokeepSuspensions(placeIdHash: string): Promise<any[]> {
    return http
        .get(`/v1/autokeep/suspension/${placeIdHash}`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

/* eslint-disable-next-line */
export async function postAutokeepAnnouncement(placeIdHash: string, inputs: any): Promise<any> {
    await http
        .post(`/v1/autokeep/announcement/${placeIdHash}`, inputs)
        .then()
        .catch((error) => {
            return Promise.reject(error.response.data);
            //return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}
/* eslint-disable-next-line */
export async function putAutokeepAnnouncement(placeIdHash: string, idHash: string, inputs: any): Promise<any> {
    await http
        .put(`/v1/autokeep/announcement/${placeIdHash}/${idHash}`, inputs)
        .then()
        .catch((error) => {
            return Promise.reject(error.response.data);
            //return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

export async function deleteAutokeepAnnouncement(placeIdHash: string, idHash: string): Promise<void> {
    await http
        .delete(`/v1/autokeep/announcement/${placeIdHash}/${idHash}`)
        .then()
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}
export async function putAutokeepSuspension(
    placeIdHash: string,
    suspend: boolean,
    spaceIdList: number[],
): Promise<void> {
    await http
        .put(`/v1/autokeep/suspension/${placeIdHash}`, { suspend, spaceIdList })
        .then()
        .catch((error) => {
            return Promise.reject(error.response.data);
        });
}

export interface AutokeepSingleAnswerQuestion {
    question: string;
    responses: string[];
}

export interface AutokeepQuestionnaire {
    localId: number;
    name: string;
    lastPublished?: number;
    lastUnpublished?: number;
    createdAt: number;
}

export interface AutokeepQuestionnaires {
    publishedId?: number;
    list: AutokeepQuestionnaire[];
}

export interface AutokeepSubmission {
    name: string;
    localId?: number; //undef means create new
    questions: AutokeepSingleAnswerQuestion[];
}

export interface AutokeepQuestionnaireSummary {
    localId: number;
    name: string;
}

export async function findAutokeepQuestions(
    placeIdHash: string,
    localId: number,
): Promise<AutokeepSingleAnswerQuestion[]> {
    return http
        .get(`/v1/autokeep/questions/${placeIdHash}/${localId}`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
    /*    return [
        {
            question: "test",
            responses: ["a", "b"],
        },
        {
            question: "test2",
            responses: ["b", "c", "d"],
        },
    ];*/
}

export async function findAutokeepQuestionnaires(placeIdHash: string): Promise<AutokeepQuestionnaires> {
    return http
        .get(`/v1/autokeep/questionnaires/${placeIdHash}`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
    /*    const ms = 1500;
    const p = new Promise((resolve) => setTimeout(resolve, ms));
    await p;
    return {
        publishedId: 13,
        //list: [],
        list: [
            {
                localId: 13,
                name: "test",
                createdAt: 1645918686,
            },
            {
                localId: 14,
                name: "wow",
                createdAt: 1645918686,
            },
        ],
    };*/
}

export async function postAutokeepQuestionnaire(placeIdHash: string, data: AutokeepSubmission) {
    await http
        .post(`/v1/autokeep/questionnaire/${placeIdHash}`, data)
        .then()
        .catch((error) => {
            return Promise.reject(error.response.data);
        });
}

export async function putAutokeepQuestionnaire(placeIdHash: string, data: AutokeepSubmission) {
    await http
        .put(`/v1/autokeep/questionnaire/${placeIdHash}`, data)
        .then()
        .catch((error) => {
            return Promise.reject(error.response.data);
        });
}

export async function findAutkeepQuestionnaireSummary(placeIdHash: string): Promise<AutokeepQuestionnaireSummary[]> {
    return http
        .get(`/v1/autokeep/questionnaire-summary/${placeIdHash}`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
    /*
    const ms = 2000;
    const p = new Promise((resolve) => setTimeout(resolve, ms));
    await p;
    //if( ms > 1000 ){
    //  return [];
    //}
    return [
        {
            localId: 1,
            name: "some questionnaire 1",
        },
        {
            localId: 2,
            name: "other questionnaire 2",
        },
    ];*/
}

//may return empty string if no data exists
export async function downloadAutokeepAll(placeIdHash: string, localId: number): Promise<string> {
    return http
        .get(`/v1/autokeep/questionnaire-responses/${placeIdHash}/${localId}`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
    /*
    const ms = 2000;
    const p = new Promise((resolve) => setTimeout(resolve, ms));
    await p;
    if (ms > 1000) {
        return ""; //not found
    }
    const rows = [
        ["name1", "city1", "some other info"],
        ["name2", "city2", "more info"],
    ];
    return rows.map((e) => e.join(",")).join("\n");*/
}

//may return empty string if no data exists
export async function downloadAutokeepRange(
    placeIdHash: string,
    localId: number,
    beginD: Date,
    endD: Date,
): Promise<string> {
    const begin = dayjs(beginD).format("YYYYMMDD");
    const end = dayjs(endD).format("YYYYMMDD");
    return http
        .get(`/v1/autokeep/questionnaire-responses-in-range/${placeIdHash}/${localId}/${begin}/${end}`)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

interface PlaceApiHoliday {
    requestedUnixtime: number;
    isHoliday: boolean;
}

export function getAutokeepHolidays(unixtimeList: number[]): Promise<PlaceApiHoliday[]> {
    const list = unixtimeList.join(",");
    return http
        .get(`/v1/country/jp/is-holiday?unixtimeList=${list}`)
        .then((response) => response.data)
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

export function getAutokeepHistory(placeIdHash: string, year: number, month: number): Promise<any> {
    return http
        .get(`/v1/autokeep/reservation-history/${placeIdHash}/${year}/${month}`)
        .then((response) => response.data)
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

// csv download of vacancy data
export async function getTimeSeriesVacancyDataByCsv(
    placeIdHash: string,
    fromDate: string,
    toDate: string,
): Promise<string> {
    const response: string = await http
        .get<string>(`/v1/places/${placeIdHash}/vacancy/csv-download`, {
            params: { fromDate: fromDate, toDate: toDate },
        })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
    return response;
}

export async function getTimeSeriesVacancyDataByJson(
    placeIdHash: string,
    fromDate: number,
    toDate: number,
): Promise<ConsoleAdapterApi.ResponseGetAnalysisUnavailableCount> {
    // TODO: console 全体のlocale logicは、再検討する必須
    const savedLang: string | null = localStorage.getItem("vacan/console/lang");
    const language = savedLang !== null ? savedLang : locale;
    return http
        .get<ConsoleAdapterApi.ResponseGetAnalysisUnavailableCount>(
            `/v1/places/${placeIdHash}/facilities/unavailable-count`,
            {
                params: { fromDate, toDate, language },
            },
        )
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

// Maps PV data
export async function getMapsPVUU(placeIdHash: string): Promise<ConsoleAdapterApi.ResponseAnalyticsData> {
    return await http
        .get<string>(`/v2/places/${placeIdHash}/analytics/maps`, { params: { usega4: true } })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: " " });
        });
}

export async function getPagesPVUU(placeIdHash: string): Promise<ConsoleAdapterApi.ResponsePagesAnalyticsData> {
    return await http
        .get<string>(`/v2/places/${placeIdHash}/analytics/pages`, { params: { usega4: true } })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: " " });
        });
}

export async function getSlideshowSetting(placeIdHash: string): Promise<boolean> {
    return await http
        .get(`/v1/places/${placeIdHash}/slideshow-setting`)
        .then((response: AxiosResponse) => {
            return response.data.useSlideshow;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

export async function putSlideshowSetting(placeIdHash: string, useSlideshow: boolean): Promise<boolean> {
    return await http
        .put(`/v1/places/${placeIdHash}/slideshow-setting`, { useSlideshow: useSlideshow })
        .then((response: AxiosResponse) => {
            return response.status === 204;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

/** Maps soldout 売り切れ早じまい */
import {
    ResponseSoldoutDisplayVacancy,
    ResponseEarlyClosingSettingSummary,
    RequestEarlyClosingSetting,
} from "@/@types/place-soldout";

export async function fetchSoldoutData(placeIdHash: string): Promise<ResponseEarlyClosingSettingSummary | undefined> {
    const timestamp = new Date().getTime() / 1000;
    return await http
        .get(`/v1/user/places/${placeIdHash}/early-closing/summary`, {
            params: { timestamp, ...wcagParam() },
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchSoldoutDisplayVacancy(
    placeIdHashList: string[],
): Promise<ResponseSoldoutDisplayVacancy[] | undefined> {
    return await http
        .get(`/v1/places/${placeIdHashList.join(",")}/vacancy`)
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function putSoldoutData(placeIdHash: string, payload: RequestEarlyClosingSetting): Promise<boolean> {
    return await http
        .put(`/v1/user/places/${placeIdHash}/early-closing/setting`, { ...payload })
        .then((response: AxiosResponse) => {
            return response.status === 204;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
}

/** @remarks 避難所訓練 evacuation drills */
export function fetchLaunchBatchEvacuationDrillList(
    page = 1,
    limit = 50,
    filterParams?: RequestFetchEvacuationDrill,
): Promise<BatchIrregularSchedulePlaceSearchSummary> {
    try {
        return http
            .get(`/v1/user/batch-editing/maps/places/evacuation-drill`, {
                params: { limit, page, ...filterParams, language: locale },
            })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export function batchUpdateEvacuationDrillPlaces(
    placeIdHashList: string[],
    publicStatus: "public" | "unpublic",
): Promise<number> {
    try {
        const drillMode = publicStatus === "public" ? "on" : "off";
        return http
            .put(`/v1/user/batch-editing/maps/places/${placeIdHashList.join(",")}/evacuation-drill/${drillMode}`)
            .then(function (response: AxiosResponse) {
                return response.status;
            })
            .catch(function (error) {
                return Promise.reject(error.message ? error : { message: "connection error", name: "" });
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

/**
 * @remarks Batch Analytics Graph Page
 * 避難所有償対応Graph
 */
// types
import {
    ResponseGetEvacuationAnalyticsGroupList,
    EvacuationAnalyticsVacancyHistoryList,
    ResponseGetMapsSubregionList,
    EvacuationAnalyticsPvByDateList,
    EvacuationAnalyticsPvByHourList,
    EvacuationAnalyticsPvByPrefectureList,
    EvacuationAnalyticsPvByCityList,
    EvacuationAnalyticsPvByReferenceSourceList,
    EvacuationAnalyticsPvByDeviceCategoryList,
    CsvDownloadType,
} from "@/@types/launch-batch-analytics";
// type guards
import {
    isResponseGetEvacuationAnalyticsGroup,
    isEvacuationAnalyticsVacancyHistoryList,
    isEvacuationAnalyticsPvByDateList,
    isEvacuationAnalyticsPvByHourList,
    isResponseGetMapsSubregionList,
    isEvacuationAnalyticsPvByPrefectureList,
    isEvacuationAnalyticsPvByCityList,
    isEvacuationAnalyticsPvByReferenceSourceList,
    isEvacuationAnalyticsPvByDeviceCatgoryList,
} from "@/@types_guard/launch-batch-analytics";

export function getBatchAnalyticsGroups(
    genre = "evacuation",
    pageName = "vacancy",
): Promise<ResponseGetEvacuationAnalyticsGroupList> {
    try {
        return http
            .get(`/v1/user/batch-analytics/${genre}/${pageName}/analytics-groups`, {
                params: { language: locale },
            })
            .then((response: AxiosResponse) => {
                if (isResponseGetEvacuationAnalyticsGroup(response.data)) {
                    return response.data;
                } else {
                    throw new Error("response type of analytics-groups is not correct");
                }
            })
            .catch((error) => {
                return Promise.reject(error.message ? error.message : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export function getBatchAnalyticsGraphData(
    genre = "evacuation",
    pageName = "vacancy",
    analyticsGroupIdHash?: string,
    fromUnixtime?: number,
    toUnixtime?: number,
): Promise<EvacuationAnalyticsVacancyHistoryList> {
    try {
        if (analyticsGroupIdHash === undefined || fromUnixtime === undefined || toUnixtime === undefined) {
            throw Error("analyticsGroupIdHash or fromUnixtime or toUnixtime is not set");
        }
        return http
            .get(`/v1/user/batch-analytics/${genre}/${pageName}/${analyticsGroupIdHash}/all-data`, {
                params: {
                    language: locale,
                    fromUnixtime: convertToZeroHHMM(fromUnixtime),
                    toUnixtime: convertToZeroHHMM(toUnixtime),
                },
            })
            .then((response: AxiosResponse) => {
                if (response.status === 204) return []; // why response.data is string here
                if (isEvacuationAnalyticsVacancyHistoryList(response.data)) {
                    return response.data;
                } else {
                    throw new Error("response type of analytics-groups graph is not correct");
                }
            })
            .catch((error) => {
                return Promise.reject(error.message ? error.message : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

export async function getBatchAnalyticsCsvDataByGroupIdHash(
    genre = "evacuation",
    pageName = "vacancy",
    analyticsGroupIdHash?: string,
    fromUnixtime?: number,
    toUnixtime?: number,
): Promise<string> {
    try {
        if (analyticsGroupIdHash === undefined || fromUnixtime === undefined || toUnixtime === undefined) {
            throw Error("analyticsGroupIdHash or fromUnixtime or toUnixtime is not set");
        }
        if (analyticsGroupIdHash === undefined || fromUnixtime === undefined || toUnixtime === undefined)
            throw Error("analyticsGroupIdHash or fromUnixtime or toUnixtime is not set");
        return await http
            .get<string>(`/v1/user/batch-analytics/${genre}/${pageName}/${analyticsGroupIdHash}/place-data/csv`, {
                params: {
                    language: locale,
                    fromUnixtime: convertToZeroHHMM(fromUnixtime),
                    toUnixtime: convertToZeroHHMM(toUnixtime),
                },
            })
            .then((response: AxiosResponse) => {
                if (response.data === undefined || response.data === "") {
                    throw Error("csv format is not correct");
                }
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error.message ? error.message : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}

/**
 * @remarks Batch Analytics Graph Page
 * エリア別 閲覧数
 */

// エリアリスト
export async function getBatchAnalyticsGaPvMapsSubregionList(): Promise<ResponseGetMapsSubregionList> {
    try {
        return await http
            .get<string>(`/v1/user/maps/subregions`)
            .then((response: AxiosResponse) => {
                if (response.status === 204) return []; // why response.data is string here
                if (isResponseGetMapsSubregionList(response.data)) {
                    return response.data;
                } else {
                    throw new Error("response type is not correct");
                }
            })
            .catch((error) => {
                return Promise.reject(error.message ? error.message : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
// グラフ + テーブル json
export async function getBatchAnalyticsGaPvWithJsonBySubregionName(
    genre = "evacuation",
    pageName = "ga",
    category: CsvDownloadType,
    subregionName?: string,
    fromUnixtime?: number,
    toUnixtime?: number,
    useGA4?: boolean,
): Promise<
    | EvacuationAnalyticsPvByDateList
    | EvacuationAnalyticsPvByHourList
    | EvacuationAnalyticsPvByPrefectureList
    | EvacuationAnalyticsPvByCityList
    | EvacuationAnalyticsPvByReferenceSourceList
    | EvacuationAnalyticsPvByDeviceCategoryList
> {
    try {
        if (subregionName === undefined || fromUnixtime === undefined || toUnixtime === undefined) {
            throw Error("subregionName or fromUnixtime or toUnixtime is not set");
        }
        if (subregionName === undefined || fromUnixtime === undefined || toUnixtime === undefined)
            throw Error("subregionName or fromUnixtime or toUnixtime is not set");

        return await http
            .get<string>(
                `/v1/user/batch-analytics/${genre}/${pageName}/subregions/${subregionName}/pv/${category}/json`,
                {
                    params: {
                        language: locale,
                        fromDate: dayjs.unix(convertToZeroHHMM(fromUnixtime)).format("YYYY-MM-DD"),
                        toDate: dayjs.unix(convertToZeroHHMM(toUnixtime)).format("YYYY-MM-DD"),
                        usega4: useGA4 === true ? "" : null,
                    },
                },
            )
            .then((response: AxiosResponse) => {
                if (response.status === 204) return []; // why response.data is string here
                if (
                    isEvacuationAnalyticsPvByDateList(response.data) ||
                    isEvacuationAnalyticsPvByHourList(response.data) ||
                    isEvacuationAnalyticsPvByPrefectureList(response.data) ||
                    isEvacuationAnalyticsPvByCityList(response.data) ||
                    isEvacuationAnalyticsPvByReferenceSourceList(response.data) ||
                    isEvacuationAnalyticsPvByDeviceCatgoryList(response.data)
                ) {
                    return response.data;
                } else {
                    throw new Error("response type is not correct");
                }
            })
            .catch((error) => {
                return Promise.reject(error.message ? error.message : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
// CSV
export async function getBatchAnalyticsGaPvCsvByCategorySubregionName(
    genre = "evacuation",
    pageName = "ga",
    category: CsvDownloadType,
    subregionName?: string,
    fromUnixtime?: number,
    toUnixtime?: number,
    useGA4?: boolean,
): Promise<string> {
    try {
        if (subregionName === undefined || fromUnixtime === undefined || toUnixtime === undefined) {
            throw Error("subregionName or fromUnixtime or toUnixtime is not set");
        }
        if (subregionName === undefined || fromUnixtime === undefined || toUnixtime === undefined)
            throw Error("subregionName or fromUnixtime or toUnixtime is not set");

        return await http
            .get<string>(
                `/v1/user/batch-analytics/${genre}/${pageName}/subregions/${subregionName}/pv/${category}/csv`,
                {
                    params: {
                        language: locale,
                        fromDate: dayjs.unix(convertToZeroHHMM(fromUnixtime)).format("YYYY-MM-DD"),
                        toDate: dayjs.unix(convertToZeroHHMM(toUnixtime)).format("YYYY-MM-DD"),
                        usega4: useGA4 === true ? "" : null,
                    },
                },
            )
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    throw new Error("response type is not correct");
                }
            })
            .catch((error) => {
                return Promise.reject(error.message ? error.message : { message: "connection error", name: "" });
            });
    } catch {
        return Promise.reject(400);
    }
}
// analytics
export async function fetchVacancySatisfactionLevel(
    analyticsGroupIdHashList: string[],
): Promise<ConsoleAdapterApi.ResponseGetAnalyticsGroupVacancySatisfactionLevelSetting[]> {
    if (analyticsGroupIdHashList.length <= 0) {
        return [];
    }
    return await http
        .get<ConsoleAdapterApi.ResponseGetAnalyticsGroupVacancySatisfactionLevelSetting[]>(
            `/v1/user/analytics-groups/vacancy-satisfaction-level`,
            {
                params: {
                    analyticsGroupIdHashList: analyticsGroupIdHashList.join(","),
                },
            },
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchWaitingSecondsHistoryAuthorizedAnalyticsGroupList(): Promise<
    ConsoleAdapterApi.ResponseGetBatchAnalyticsGroup[]
> {
    return http
        .get<ConsoleAdapterApi.ResponseGetBatchAnalyticsGroup[]>(
            `/v1/user/batch-analytics/vacancy-history/waiting-seconds-history/analytics-groups`,
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchWaitingMinutesTimeseriesList(
    analyticsGroupIdHash: string,
    targetDate: string,
): Promise<ConsoleAdapterApi.ResponseVacancyTimeseriesData[]> {
    return http
        .get<ConsoleAdapterApi.ResponseVacancyTimeseriesData[]>(
            `/v1/user/batch-analytics/analytics-groups/${analyticsGroupIdHash}/analysis/vacancy/timeseries/waitingminutes`,
            { params: { targetDate } },
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchWaitingCountHistoryAuthorizedAnalyticsGroupList(): Promise<
    ConsoleAdapterApi.ResponseGetBatchAnalyticsGroup[]
> {
    return http
        .get<ConsoleAdapterApi.ResponseGetBatchAnalyticsGroup[]>(
            `/v1/user/batch-analytics/vacancy-history/waiting-count-history/analytics-groups`,
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchWaitingCountTimeseriesList(
    analyticsGroupIdHash: string,
    targetDate: string,
): Promise<ConsoleAdapterApi.ResponseVacancyTimeseriesData[]> {
    return http
        .get<ConsoleAdapterApi.ResponseVacancyTimeseriesData[]>(
            `/v1/user/batch-analytics/analytics-groups/${analyticsGroupIdHash}/analysis/vacancy/timeseries/waitingcount`,
            { params: { targetDate } },
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchAnalysisCsvDownloadAuthorizedAnalyticsGroupWithPlaceList(): Promise<
    ConsoleAdapterApi.ResponseGetBatchAnalyticsGroupWithPlace[]
> {
    return http
        .get<ConsoleAdapterApi.ResponseGetBatchAnalyticsGroupWithPlace[]>(
            `/v1/user/batch-analytics/vacancy-history/csv-download/analytics-groups/places`,
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchTimeseriesListInCsv(
    analyticsGroupIdHash: string,
    placeIdHash: string,
    targetStartDate: string,
    targetEndDate: string,
): Promise<string> {
    return httpForHeavyRequest
        .get<string>(
            `/v1/user/batch-analytics/analytics-groups/${analyticsGroupIdHash}/places/${placeIdHash}/analysis/vacancy/timeseries/csv`,
            {
                params: {
                    targetStartDate: targetStartDate,
                    targetEndDate: targetEndDate,
                },
            },
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchWaitingSecondsDistributionAuthorizedAnalyticsGroupWithPlaceList(): Promise<
    ConsoleAdapterApi.ResponseGetBatchAnalyticsGroupWithPlace[]
> {
    return http
        .get<ConsoleAdapterApi.ResponseGetBatchAnalyticsGroupWithPlace[]>(
            `/v1/user/batch-analytics/vacancy-history/waiting-seconds-distribution/analytics-groups/places`,
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchUserWaitingStatisticList(
    analyticsGroupIdHash: string,
    placeIdHash: string,
    startDate: string,
    endDate: string,
): Promise<ConsoleAdapterApi.ResponseGetPlaceVacancySatisfactionRatio[]> {
    return await http
        .get(
            `/v1/user/batch-analytics/analytics-groups/${analyticsGroupIdHash}/places/${placeIdHash}/satisfaction-ratio`,
            {
                params: {
                    targetStartDate: startDate,
                    targetEndDate: endDate,
                },
            },
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
export async function fetchUserWaitingRawScatterList(
    analyticsGroupIdHash: string,
    placeIdHash: string,
    startDate: string,
    endDate: string,
): Promise<ConsoleAdapterApi.WaitingMinutesTimeseries[]> {
    return await http
        .get(
            `/v1/user/batch-analytics/analytics-groups/${analyticsGroupIdHash}/places/${placeIdHash}/waiting-seconds-distribution/vacancy/timeseries/waitingminutes`,
            {
                params: {
                    targetStartDate: startDate,
                    targetEndDate: endDate,
                },
            },
        )
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

/**
 * for Patokids
 */

export async function fetchPatokidsLatestStatus(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponsePatokidsLatestStatus | undefined> {
    return http
        .get(`/v1/user/place/${placeIdHash}/patokids/latest-status`)
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return undefined;
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export async function fetchPatokidsTimeline(placeIdHash: string): Promise<ConsoleAdapterApi.ResponsePatokidsTimeline> {
    const now = dayjs();
    const weekAgo = dayjs().subtract(7, "days");

    return http
        .get(`/v1/user/place/${placeIdHash}/patokids/timeline`, {
            params: { fromDate: weekAgo.format("YYYY-MM-DD"), toDate: now.format("YYYY-MM-DD") },
        })
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export function fetchPatokidsImageLog(placeIdHash: string): Promise<ConsoleAdapterApi.ResponsePatokidsImageList> {
    const now = dayjs();
    const weekAgo = dayjs().subtract(7, "days");

    return http
        .get(`/v1/user/place/${placeIdHash}/patokids/images`, {
            params: { fromDate: weekAgo.format("YYYY-MM-DD"), toDate: now.format("YYYY-MM-DD") },
        })
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return [];
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

/**
 * Activities
 */

export function fetchActivitySetting(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseActivitySetting | undefined> {
    return http
        .get(`/v1/user/places/${placeIdHash}/activity/setting`)
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return undefined;
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export function createActivityLog(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestActivityLog,
): Promise<boolean> {
    return http
        .post(`/v1/user/places/${placeIdHash}/activity/logs`, payload)
        .then((response: AxiosResponse) => response.status === 201)
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export function fetchCurrentActivity(
    placeIdHash: string,
): Promise<ConsoleAdapterApi.ResponseCurrentActivity | undefined> {
    return http
        .get(`/v1/user/places/${placeIdHash}/activity/logs/current`)
        .then((response: AxiosResponse) => {
            if (response.status === 204) {
                return undefined;
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

type ActivityQueryParam = {
    activityIdHash?: string;
    limit: number;
    page: number;
    sortConditionList?: string[];
};

export function fetchPlaceActivityLogList(
    queryParams: ActivityQueryParam,
): Promise<ConsoleAdapterApi.ResponseLatestActivityLogSearchSummary> {
    const params = {
        activityIdHash: queryParams.activityIdHash,
        limit: queryParams.limit,
        page: queryParams.page,
        sort: queryParams.sortConditionList?.join(","),
    };
    return http
        .get("/v1/user/places/activity/logs/latest", { params })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.respnose ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export type ActivityHistoryQueryParam = {
    placeIdHash?: string;
    fromUnixtime: number;
    toUnixtime: number;
    limit: number;
    page: number;
    sortConditionList?: string[];
};

export function fetchActivityLogHistory(
    queryParams: ActivityHistoryQueryParam,
): Promise<ConsoleAdapterApi.ResponseActivityLogHistorySearchSummary> {
    const params = {
        placeIdHash: queryParams.placeIdHash,
        fromUnixtime: queryParams.fromUnixtime,
        toUnixtime: queryParams.toUnixtime,
        limit: queryParams.limit,
        page: queryParams.page,
        sort: queryParams.sortConditionList?.join(","),
    };
    return http
        .get("/v1/user/places/activity/logs/timeline", { params })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.respnose ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export function fetchActivityLogHistoryCsv(
    queryParams: Omit<ActivityHistoryQueryParam, "limit" | "page">,
): Promise<string> {
    const params = {
        placeIdHash: queryParams.placeIdHash,
        fromUnixtime: queryParams.fromUnixtime,
        toUnixtime: queryParams.toUnixtime,
        sort: queryParams.sortConditionList?.join(","),
    };
    return http
        .get("/v1/user/places/activity/logs/timeline/csv", { params })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.respnose ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export function fetchActivityList(): Promise<ConsoleAdapterApi.ResponseSelectableActivity[]> {
    return http
        .get("/v1/user/places/activity/selectable")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.respnose ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export function fetchActivityPlaceList(): Promise<ConsoleAdapterApi.ResponseActivityPlace[]> {
    return http
        .get("/v1/user/activity/places")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(
                error.respnose ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export function postInternalMemo(
    placeIdHash: string,
    payload: ConsoleAdapterApi.RequestPlaceInternalMemo,
): Promise<boolean> {
    return http
        .post(`/v1/places/${placeIdHash}/internal-memo`, payload)
        .then((response: AxiosResponse) => response.status === 201)
        .catch((error) => {
            return Promise.reject(
                error.response
                    ? (error.response.data as Record<string, unknown>).errors
                    : { message: "connection error", name: "" },
            );
        });
}

// ナンバープレート検知
export async function getNumberPlateHistoryByCsv(
    placeIdHash: string,
    fromDate: string,
    toDate: string,
): Promise<ConsoleAdapterApi.ResponseNumberPlateCsv> {
    const response: ConsoleAdapterApi.ResponseNumberPlateCsv = await http
        .get<ConsoleAdapterApi.ResponseNumberPlateCsv>(`/v1/places/${placeIdHash}/car-number-plate/history/csv`, {
            params: { fromDate: fromDate, toDate: toDate },
        })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error.message ? error : { message: "connection error", name: "" });
        });
    return response;
}

export async function fetchCongestionTrends(
    placeIdHashList: string[],
    todayUnixtime: number,
): Promise<ConsoleAdapterApi.ResponseCongestionTrends[]> {
    const lang = getLocale();
    return await http
        .get(`/v1/places/${placeIdHashList.join(",")}/congestion/trends`, {
            params: { todayUnixtime, lang },
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => Promise.reject(error));
}

export async function fetchCongestionLogs({
    placeIdHashList,
    unit,
    fromDate,
    endDate,
    csvDownload = false,
    type = "unavailableCount",
    plan = "free",
    compareBase = "value",
    csvGraphicValue,
}: {
    placeIdHashList: string[];
    unit: GraphicUnit;
    fromDate?: Date;
    endDate?: Date;
    csvDownload?: boolean;
    type?: ConsoleAdapterApi.AggregateType;
    plan?: "free" | "advanced";
    compareBase?: AggregateResponseBase;
    csvGraphicValue?: ConsoleAdapterApi.AggregateGraphUnit;
}): Promise<ConsoleAdapterApi.ResponseCongestionLog[] | string> {
    const lang = getLocale();

    return await http
        .get(`/v1/places/${placeIdHashList.join(",")}/congestion/logs`, {
            params: {
                unit,
                fromUnixtime: dayjs(dayjs(fromDate).format("YYYYMMDD 00:00:00")).unix(),
                endUnixtime: dayjs(dayjs(endDate).format("YYYYMMDD 23:59:59")).unix(),
                csvDownload,
                type,
                plan,
                compareBase,
                csvGraphicValue,
                lang,
            },
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => Promise.reject(error));
}

export async function fetchPlanList(): Promise<ConsoleAdapterApi.ResponseAnalyticsPlan[]> {
    const lang = getLocale();
    return await http
        .get(`/v1/plans/ais-analytics`, {
            params: {
                lang,
            },
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error) => Promise.reject(error));
}
