import { InternalAxiosRequestConfig } from "axios";

export class ExpiredAccessTokenError implements Error {
    public readonly name: string = "ExpiredAccessTokenError";
    public readonly message: string;
    public readonly httpStatusCode: number;
    public readonly response: undefined;
    public readonly config: InternalAxiosRequestConfig;

    constructor(axiosRequestConfig: InternalAxiosRequestConfig) {
        this.message = "Expired accessToken";
        this.httpStatusCode = 401;
        this.config = axiosRequestConfig;
    }
}
