
import Vue, { PropType } from "vue";
import { DirectiveBinding } from "vue/types/options";
import { mapMutations } from "vuex";
import Icon from "@/components/v/icon.vue";
import DropdownMenu from "@/components/v/dropdown.vue";
import SvgLogo from "@/components/svg-logo.vue";
import LanguagePromp from "@/components/language-prompt.vue";
import { signout } from "@/api/console.adapter.api";
import { decode } from "jsonwebtoken";
import EnvBar from "@/components/common/env.bar.vue";
import { RequestFetchHeaderResult } from "../../store/auth-module";
import { setLocale } from "@/utils/i18n";
import { removeMigrationStatus } from "@/api/migration/migration-v2.api";

type PlaceGroup = { placeGroupIdHash: string; placeGroupName: string };

export default Vue.extend({
  name: "component-place-header",
  components: {
    Icon,
    DropdownMenu,
    SvgLogo,
    LanguagePromp,
    EnvBar,
  },
  directives: {
    cutTitle: {
      bind(el: HTMLElement, binding: DirectiveBinding) {
        const btnName: string = binding.value.name;
        const title: undefined | string = binding.value.title;
        if (title === undefined) return;
        if (btnName === "place-title") {
          if (title.length > 22) {
            const shortTitle: string | undefined = title?.substring(0, 21);
            const newTitle: string | undefined = shortTitle?.concat("...");
            if (!newTitle) return;
            el.innerText = newTitle;
          } else {
            el.innerText = title;
          }
        } else if (btnName === "place-address") {
          if (title.length > 27) {
            const shortTitle: string | undefined = title?.substring(0, 26);
            const newTitle: string | undefined = shortTitle?.concat("...");
            if (!newTitle) return;
            el.textContent = newTitle;
          } else {
            el.textContent = title;
          }
        }
      },
    },
  },
  props: {
    usePlaceGroupMenu: Boolean,
    usePlacePulldownMenu: Boolean,
    placeGroupList: Array as PropType<PlaceGroup[]>,
    selectingPlaceGroup: Object as PropType<PlaceGroup>,
  },
  data(): {
    showLanguagePromp: boolean;
    showShopDropdownMenu: boolean;
    showUserDropdownMenu: boolean;
    showPlaceGroupMenu: boolean;
    showPlacePulldownMenu: boolean;
    searchedPlaceName: string;
    showSearchedPlaceEmpty: boolean;
    showSearchedPlaceHasMore: boolean;
    showSearchError: boolean;
  } {
    return {
      showLanguagePromp: false,
      showShopDropdownMenu: false as boolean,
      showUserDropdownMenu: false as boolean,
      showPlaceGroupMenu: false as boolean,
      showPlacePulldownMenu: false as boolean,
      searchedPlaceName: "",
      showSearchedPlaceEmpty: false as boolean,
      showSearchedPlaceHasMore: false as boolean,
      showSearchError: false as boolean,
    };
  },
  computed: {
    showUsePlacePulldownTitle(): string {
      return this.$route.path.includes("/launch")
        ? (this.$i18n.t("Launch.header.placeTitle") as string)
        : this.selectingPlace
        ? this.selectingPlace.name
        : "-";
    },
    userName(): string {
      const decodeToken: any = decode(localStorage.getItem("session/tokens/accessToken") || "");
      return decodeToken.username || "";
    },
    authorizedPlaceList(): any[] {
      return this.$store.state.auth.placeList;
    },
    selectingPlace(): any {
      return this.$store.state.auth.selectingPlace;
    },
  },
  watch: {
    usePlacePulldownMenu: {
      handler(newValue: boolean) {
        if (newValue) {
          this.$store.dispatch("auth/fetchHeaderAuthorizedPlaceList", this.searchedPlaceName).then((result) => {
            this.showSearchedPlaceHasMore = result.hasMore;
            this.showSearchError = result.status >= 400;
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("auth/verifySelectedPlace");
  },
  methods: {
    ...mapMutations("menu", ["setWcagDialogShown"]),
    userNameMultipleRow(str: string): string {
      return str.length > 80 ? `${str.slice(0, 75)}...` : str;
    },
    askClicked() {
      window.open("https://support.vacancorp.com/hc/ja/requests/new");
    },
    languageClicked() {
      this.showLanguagePromp = true;
      // to focus language list
      setTimeout(() => {
        this.showUserDropdownMenu = false;
      }, 5);
    },
    closeLanguagePromp() {
      this.showLanguagePromp = false;
    },
    selectAndCloseLanguagePromp() {
      this.showLanguagePromp = false;
      setTimeout(() => {
        this.showUserDropdownMenu = false;
      }, 5);
      // select a language
      setLocale(this.$i18n.locale);
    },
    // go to page 待ち時間履歴
    top() {
      this.$router.push("/places");
    },
    async logout() {
      signout()
        .then(() => {
          localStorage.setItem("session/tokens/accessToken", "");
          localStorage.setItem("session/tokens/refreshToken", "");
          this.clearSessionStorage("spaces/placeId");
          this.clearSessionStorage("spaces/layoutId");
          removeMigrationStatus();
          this.clearVuexData();
          window.location.href = "/login";
        })
        .catch((e) => {
          localStorage.setItem("session/tokens/accessToken", "");
          localStorage.setItem("session/tokens/refreshToken", "");
          removeMigrationStatus();
          window.location.href = "/login";
        });
    },
    clearSessionStorage(keyName: string) {
      if ("sessionStorage" in window) {
        sessionStorage.removeItem(keyName);
      } else {
        console.error("session storage is not supported.");
      }
    },
    clearVuexData() {
      this.$store.commit("RESET");
    },
    selectPlace(place: any) {
      this.showPlacePulldownMenu = false;
      this.$store.commit("auth/setSelectingPlace", place);
      this.$emit("select-place");
    },
    async searchPlace() {
      const result: RequestFetchHeaderResult = await this.$store.dispatch(
        "auth/fetchHeaderAuthorizedPlaceList",
        this.searchedPlaceName,
      );

      this.showSearchedPlaceHasMore = result.hasMore;
      this.showSearchError = result.status >= 400;
    },

    showWcagDialog() {
      this.setWcagDialogShown(true);
    },
  },
});
