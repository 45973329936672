import Vue from "vue";
import router from "@/router";
import VueGtag from "vue-gtag";

const GA4ID = process.env.NODE_ENV === "production" ? "G-DWRT7WVY6S" : "G-ZTZC7J8J0T";

Vue.use(
    VueGtag,
    {
        config: {
            id: GA4ID,
        },
    },
    router,
);
