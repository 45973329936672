import {
    ResponseGetEvacuationAnalyticsGroup,
    EvacuationAnalyticsVacancyHistory,
    EvacuationAnalyticsPvByDate,
    EvacuationAnalyticsPvByHour,
    ResponseGetMapsSubregion,
    EvacuationAnalyticsPvByPrefecture,
    EvacuationAnalyticsPvByCity,
    EvacuationAnalyticsPvByReferenceSource,
    EvacuationAnalyticsPvByDeviceCategory,
} from "@/@types/launch-batch-analytics";
export function isResponseGetEvacuationAnalyticsGroup(data: unknown[]) {
    return (
        Array.isArray(data) &&
        data.length > 0 &&
        (data[0] as ResponseGetEvacuationAnalyticsGroup).analyticsGroupIdHash !== undefined
    );
}

export function isEvacuationAnalyticsVacancyHistoryList(data: unknown[]) {
    return (
        Array.isArray(data) &&
        data.length > 0 &&
        (data[0] as EvacuationAnalyticsVacancyHistory).date !== undefined &&
        (data[0] as EvacuationAnalyticsVacancyHistory).time !== undefined
    );
}

export function isResponseGetMapsSubregionList(data: unknown[]) {
    return (
        Array.isArray(data) &&
        data.length > 0 &&
        (data[0] as ResponseGetMapsSubregion).subregionName !== undefined &&
        typeof (data[0] as ResponseGetMapsSubregion).subregionName === "string"
    );
}

export function isEvacuationAnalyticsPvByDateList(data: unknown[]) {
    return (
        Array.isArray(data) &&
        data.length > 0 &&
        (data[0] as EvacuationAnalyticsPvByDate).dateUnixtime !== undefined &&
        (data[0] as EvacuationAnalyticsPvByDate).pv !== undefined &&
        typeof (data[0] as EvacuationAnalyticsPvByDate).dateUnixtime === "number" &&
        typeof (data[0] as EvacuationAnalyticsPvByDate).pv === "number"
    );
}
export function isEvacuationAnalyticsPvByHourList(data: unknown[]) {
    return (
        Array.isArray(data) &&
        data.length > 0 &&
        (data[0] as EvacuationAnalyticsPvByHour).dateString !== undefined &&
        (data[0] as EvacuationAnalyticsPvByHour).pv !== undefined &&
        typeof (data[0] as EvacuationAnalyticsPvByHour).dateString === "string" &&
        typeof (data[0] as EvacuationAnalyticsPvByHour).pv === "number"
    );
}

export function isEvacuationAnalyticsPvByPrefectureList(data: unknown[]) {
    return (
        Array.isArray(data) &&
        data.length > 0 &&
        (data[0] as EvacuationAnalyticsPvByPrefecture).prefecture !== undefined &&
        (data[0] as EvacuationAnalyticsPvByPrefecture).pv !== undefined &&
        typeof (data[0] as EvacuationAnalyticsPvByPrefecture).prefecture === "string" &&
        typeof (data[0] as EvacuationAnalyticsPvByPrefecture).pv === "number"
    );
}

export function isEvacuationAnalyticsPvByCityList(data: unknown[]) {
    return (
        Array.isArray(data) &&
        data.length > 0 &&
        (data[0] as EvacuationAnalyticsPvByCity).city !== undefined &&
        (data[0] as EvacuationAnalyticsPvByCity).pv !== undefined &&
        typeof (data[0] as EvacuationAnalyticsPvByCity).city === "string" &&
        typeof (data[0] as EvacuationAnalyticsPvByCity).pv === "number"
    );
}

export function isEvacuationAnalyticsPvByReferenceSourceList(data: unknown[]) {
    return (
        Array.isArray(data) &&
        data.length > 0 &&
        (data[0] as EvacuationAnalyticsPvByReferenceSource).referenceSource !== undefined &&
        (data[0] as EvacuationAnalyticsPvByReferenceSource).pv !== undefined &&
        typeof (data[0] as EvacuationAnalyticsPvByReferenceSource).referenceSource === "string" &&
        typeof (data[0] as EvacuationAnalyticsPvByReferenceSource).pv === "number"
    );
}
export function isEvacuationAnalyticsPvByDeviceCatgoryList(data: unknown[]) {
    return (
        Array.isArray(data) &&
        data.length > 0 &&
        (data[0] as EvacuationAnalyticsPvByDeviceCategory).deviceCategory !== undefined &&
        (data[0] as EvacuationAnalyticsPvByDeviceCategory).rate !== undefined &&
        typeof (data[0] as EvacuationAnalyticsPvByDeviceCategory).deviceCategory === "string" &&
        typeof (data[0] as EvacuationAnalyticsPvByDeviceCategory).rate === "number"
    );
}
