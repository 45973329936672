import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";
import http from "@/api/datastore/http_console";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { V2MigrationStatus } from "./migration-status";

function fetchConsoleV2MigrationStatus(): Promise<ConsoleAdapterApi.ResponseGetMemberV2Migration> {
    try {
        return http
            .get(`/v1/user/console-v2/using-status`)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => Promise.reject(error.message ? error : { message: "connection error", name: "" }));
    } catch {
        return Promise.reject(400);
    }
}

export async function getMigrationStatus(): Promise<V2MigrationStatus> {
    const migrationInformation: string = localStorage.getItem("vacan/console/migrationToV2") || "";
    if (migrationInformation.length) {
        try {
            const val = JSON.parse(migrationInformation) as {
                migrationInformation: ConsoleAdapterApi.ResponseGetMemberV2Migration;
                checkedUnixtime: number;
            };
            const v2MigrationStatus = new V2MigrationStatus(val.checkedUnixtime, val.migrationInformation);

            // 1日以内にチェックした場合は、ローカルストレージの情報を返す
            if (v2MigrationStatus.isCheckedRecently()) {
                return v2MigrationStatus;
            }
        } catch {
            // localStorageの書き換えなどを考慮してtry句で囲む
        }
    }

    const data = await fetchConsoleV2MigrationStatus();
    localStorage.setItem(
        "vacan/console/migrationToV2",
        JSON.stringify({ migrationInformation: data, checkedUnixtime: dayjs().unix() }),
    );

    return new V2MigrationStatus(dayjs().unix(), data);
}

export function removeMigrationStatus(): void {
    localStorage.removeItem("vacan/console/migrationToV2");
}
