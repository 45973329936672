import { AxiosError, AxiosResponse } from "axios";
import $httpC from "./datastore/http_console";
import Cavi from "cavi-mgr";

import $http from "./datastore/http_console";

import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";

export function fetchMapListByPlaceGroup(placeGroupIdHash: string): Promise<ConsoleAdapterApi.ResponseGetMap[]> {
    const baseUrl = `/v1/place-groups/${placeGroupIdHash}/maps`;

    try {
        return $http
            .get<ConsoleAdapterApi.ResponseGetMap[]>(baseUrl)
            .then((response) => response.data)
            .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

export function fetchMapWithMapPointList(
    placeGroupIdHash: string,
    mapId: number,
): Promise<ConsoleAdapterApi.ResponseGetMapWithMapPointList> {
    const baseUrl = `/v1/place-groups/${placeGroupIdHash}/maps/${mapId}`;

    try {
        return $http
            .get<ConsoleAdapterApi.ResponseGetMapWithMapPointList>(baseUrl)
            .then((response) => response.data)
            .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

export function createMap(placeGroupIdHash: string, mapName: string): Promise<ConsoleAdapterApi.ResponseGetMap> {
    return $http
        .post(`/v1/place-groups/${placeGroupIdHash}/maps`, { mapName })
        .then((response) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
}

export function updateMap(
    placeGroupIdHash: string,
    mapId: number,
    mapName: string,
): Promise<ConsoleAdapterApi.ResponseGetMap> {
    return $http
        .put(`/v1/place-groups/${placeGroupIdHash}/maps/${mapId}`, { mapName })
        .then((response) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
}

export function deleteMap(placeGroupIdHash: string, mapId: number): Promise<boolean> {
    return $http
        .delete(`/v1/place-groups/${placeGroupIdHash}/maps/${mapId}`)
        .then((response) => response.status === 204)
        .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
}

export function fetchMapPointLinkList(
    placeGroupIdHash: string,
    fromMapIdList?: number[],
): Promise<ConsoleAdapterApi.ResponseGetMapPointLink[]> {
    const baseUrl = `/v1/place-groups/${placeGroupIdHash}/map-point-links`;
    const params: Record<string, any> = {
        fromMapIdList: fromMapIdList !== undefined ? fromMapIdList.join(",") : undefined,
    };

    try {
        return $http
            .get<ConsoleAdapterApi.ResponseGetMapPointLink[]>(baseUrl, { params })
            .then((response) => response.data)
            .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

export function fetchMapPointListFromMapId(placeGroupIdHash: string, mapId: number, mapPointTypeCategory?: string) {
    const baseUrl = `/v1/place-groups/${placeGroupIdHash}/maps/${mapId}/map-points`;
    const params: Record<string, any> = { mapPointTypeCategory };

    try {
        return $http
            .get<ConsoleAdapterApi.ResponseGetMapPoint[]>(baseUrl, { params })
            .then((response) => response.data)
            .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

export function createMapPointLink(placeGroupIdHash: string, link: ConsoleAdapterApi.RequestPostMapPointLink) {
    const baseUrl = `/v1/place-groups/${placeGroupIdHash}/map-point-links`;
    const body: ConsoleAdapterApi.RequestPostMapPointLink = link;

    try {
        return $http
            .post<ConsoleAdapterApi.ResponseGetMapPointLink>(baseUrl, body)
            .then((response) => response.data)
            .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
    } catch {
        return Promise.reject(400);
    }
}

/** ---- Cavi QR Map Management ---- */
/** Query (GET) */
// Map& Pins 一覽
export async function getMapAndPinsList(
    placeGroupId: string,
    mapId: number,
): Promise<Cavi.ResponseGetMapWithMapPointList> {
    try {
        return await $httpC
            .get(`/v1/place-groups/${placeGroupId}/maps/${mapId}`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// Pin detail 一覽
export async function getDetailByPinId(
    placeGroupId: string,
    mapId: number,
    mapPointId: string,
): Promise<Cavi.MapPointDetailDisplay> {
    try {
        return await $httpC
            .get(`/v1/place-groups/${placeGroupId}/maps/${mapId}/map-points/${mapPointId}`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// Category一覽
export async function getCategoryList(placeGroupId: string): Promise<Cavi.MapPointType[]> {
    try {
        return await $httpC
            .get(`/v1/place-groups/${placeGroupId}/service-map-point-type`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// PlacesList 一覽 belong to one destination category
export async function getPlaceListByCategory(placeGroupId: string): Promise<ConsoleAdapterApi.ResponseGetMapPlace[]> {
    try {
        return await $httpC
            .get(`/v1/place-groups/${placeGroupId}/places/cavi`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
/** Commands (CREATE,DELETE,UPDATE) */
// Pin detail 追加
export async function createPinDetail(
    placeGroupId: string,
    mapId: number,
    data: ConsoleAdapterApi.RequestPostMapPoint,
): Promise<ConsoleAdapterApi.ResponseGetMapPoint> {
    try {
        return await $httpC
            .post(`/v1/place-groups/${placeGroupId}/maps/${mapId}/map-points`, data)
            .then((response: AxiosResponse) => {
                return response.status === 201 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// Pin detail 更新
export async function updatePinDetail(
    placeGroupId: string,
    mapId: number,
    mapPointId: string,
    data: ConsoleAdapterApi.RequestPutMapPoint,
): Promise<ConsoleAdapterApi.ResponseGetMapPoint> {
    try {
        return await $httpC
            .put(`/v1/place-groups/${placeGroupId}/maps/${mapId}/map-points/${mapPointId}`, data)
            .then((response: AxiosResponse) => {
                return response.status === 201 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// Pin detail 削除
export async function deletePinDetail(placeGroupId: string, mapId: number, mapPointId: string): Promise<number> {
    return await $httpC
        .delete(`/v1/place-groups/${placeGroupId}/maps/${mapId}/map-points/${mapPointId}`)
        .then((response: AxiosResponse) => {
            return response.status;
        })
        .catch((error: AxiosError) => {
            return typeof error.response === "undefined" ? Promise.reject(500) : Promise.reject(error.response.status);
        });
}
// Map 追加/更新
export async function uploadMapImage(
    placeGroupId: string,
    mapId: number,
    data: ConsoleAdapterApi.RequestPutMapImage,
): Promise<ConsoleAdapterApi.ResponseGetMap> {
    return await $httpC
        .put(`/v1/place-groups/${placeGroupId}/maps/${mapId}/image`, data)
        .then((response: AxiosResponse) => {
            return response.status === 201 ? response.data : Promise.reject(400);
        })
        .catch((error: AxiosError) => {
            return typeof error.response === "undefined" ? Promise.reject(500) : Promise.reject(error.response.status);
        });
}

export function deleteMapPointLink(placeGroupIdHash: string, mapPointLinkId: number): Promise<void> {
    const baseUrl = `/v1/place-groups/${placeGroupIdHash}/map-point-links/${mapPointLinkId}`;

    return $http
        .delete(baseUrl)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
}

export function fetchUserAuthorizedPlaceGroupList() {
    const baseUrl = "/v1/user/place-groups";

    return $http
        .get(baseUrl)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
}

export async function fetchRootCategoryList(): Promise<ConsoleAdapterApi.ResponseGetPlaceCategory[]> {
    const baseUrl = "/v1/place-categories/root-categories";
    return $http
        .get(baseUrl)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
}

export async function registerNewPlace(
    placeGroupIdHash: string,
    placeName: string,
    rootPlaceCategoryType: string,
): Promise<void> {
    const baseUrl = "/v1/places";
    // Update the type of this once its done
    const body = {
        placeGroupIdHash,
        nameI18n: {
            fallbackLanguage: "ja",
            languageList: { ja: placeName },
        },
        rootPlaceCategoryType,
    };
    return $http
        .post(baseUrl, body)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response !== undefined ? error.response.status : 500));
}

export default {
    getMapAndPinsList,
    getDetailByPinId,
    getCategoryList,
    getPlaceListByCategory,
    createPinDetail,
    updatePinDetail,
    deletePinDetail,
    uploadMapImage,
};
