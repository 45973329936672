function isIE11(): boolean {
    return "MSInputMethodContext" in window && "documentMode" in document;
}

function _limit24Hours(hh: string) {
    if (Number(hh) > 23) return 23;
    else if (Number(hh) < 0) return 0;
    else return hh;
}

function _limitMinute(mm: string) {
    if (Number(mm) > 59) return 59;
    else if (Number(mm) < 0) return 0;
    else return mm;
}

function checkTimeFormat(newValueString: string): string {
    if (!/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(newValueString)) {
        if (newValueString.length === 2 && newValueString.indexOf(":") === -1) {
            return newValueString + ":";
        } else if (newValueString.length >= 4 && newValueString.indexOf(":") === -1) {
            return newValueString.substring(0, 2) + ":" + newValueString.substring(2, 4);
        } else if (newValueString.length >= 5) {
            return _limit24Hours(newValueString.substring(0, 2)) + ":" + _limitMinute(newValueString.substring(3, 5));
        } else {
            return newValueString;
        }
    } else {
        return newValueString;
    }
}

export default {
    isIE11,
    checkTimeFormat,
};
