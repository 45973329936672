import { AxiosError } from "axios";
import { ActionTree, MutationTree, GetterTree, ActionContext } from "vuex";
import SpaceAdminApi from "../api/space-admin.api";
import { SpaceAdminState } from "spaces-mgr";
import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";
import { AppState } from "@/store";

export const initialState = (): SpaceAdminState => ({
    placeId: 0,
    layoutId: 0,
    isSelected: false,
    selectedSpaceOutlineIndex: -1,
    spacesOutlinesList: [] as ConsoleAdapterApi.SpaceOutline[],
    showAlertWindow: {
        state: false,
        title: "",
        subtitle: "",
    },
    isLoading: false, // check for waiting response
});

export const state: SpaceAdminState = initialState();

/** @remarks
 * Use mutations to write state */
export const mutations: MutationTree<SpaceAdminState> = {
    setPlaceId(state: SpaceAdminState, newVal: number): void {
        state.placeId = newVal;
    },
    setLayoutId(state: SpaceAdminState, newVal: number): void {
        state.layoutId = newVal;
    },
    setIsLoading(state: SpaceAdminState, newStatus: boolean): void {
        state.isLoading = newStatus;
    },
    setIsSelected(state: SpaceAdminState, selected: boolean): void {
        state.isSelected = selected;
    },
    setSelectedSpaceOutlineIndex(state: SpaceAdminState, itemId: number): void {
        state.selectedSpaceOutlineIndex = itemId;
    },
    setSpacesOutlinesList(state: SpaceAdminState, list: ConsoleAdapterApi.SpaceOutline[]) {
        state.spacesOutlinesList = list;
    },
    updateSpaceOutlineAt(state: SpaceAdminState, payload: { data: ConsoleAdapterApi.SpaceOutline; atIndex: number }) {
        state.spacesOutlinesList[payload.atIndex] = payload.data;
    },
    deleteSpaceOutlineAt(state: SpaceAdminState, atIndex: number) {
        state.spacesOutlinesList.splice(atIndex, 1);
    },
};

/** @remarks
 * Use actions to connect APIs */
export const actions: ActionTree<SpaceAdminState, AppState> = {
    updateSpaceData(
        { state }: ActionContext<SpaceAdminState, AppState>,
        payload: ConsoleAdapterApi.SpaceOutline,
    ): Promise<number> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceAdminApi.updateSpaceData(state.layoutId, payload.spaceId, payload)
                .then((statusCode: number) => {
                    return resolve(statusCode);
                })
                .catch((error: AxiosError) => {
                    // return to UI vue
                    return reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
    addSpaceData(
        { state }: ActionContext<SpaceAdminState, AppState>,
        payload: ConsoleAdapterApi.SpaceOutline,
    ): Promise<number> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceAdminApi.addSpaceData(state.layoutId, payload)
                .then((statusCode: number) => {
                    return resolve(statusCode);
                })
                .catch((error: AxiosError) => {
                    // return to UI vue
                    return reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
    deleteSpaceData({ state }: ActionContext<SpaceAdminState, AppState>, spaceId: number): Promise<number> {
        return new Promise((resolve, reject) => {
            // Call api
            SpaceAdminApi.deleteSpaceData(state.layoutId, spaceId)
                .then((statusCode: number) => {
                    return resolve(statusCode);
                })
                .catch((error: AxiosError) => {
                    // return to UI vue
                    return reject({ code: 404, message: error !== undefined ? error.message : "" });
                });
        });
    },
};

/** @remarks
 * Use getter to get state data rather than direct access */
export const getters: GetterTree<SpaceAdminState, AppState> = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
