
import Vue from "vue";
import LanguageList from "@/components/language-prompt-list.vue";

export default Vue.extend({
  name: "component-menu-language-prompt",
  components: { LanguageList },
  props: {
    loginPrompt: Boolean,
  },
  methods: {
    rowClicked(language: "zh_TW" | "ja_JP") {
      this.$i18n.locale = language;
      this.$emit("select-and-close-view");
    },
    closeView() {
      this.$emit("close-view");
    },
  },
});
