
import Vue from "vue";
export default Vue.extend({
  name: "components-svg-logo",
  props: {
    color: {
      type: String,
      validator: (value: string) => ["white", "gray-2", "gray-5", "violet-1"].indexOf(value) !== -1,
      required: true,
    },
  },
});
