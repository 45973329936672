import { BatchManualInputPlace } from "@/@types/launch";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { AppState } from "..";

export type BatchVacancyState = {
    targetPlaceList: BatchManualInputPlace[];
};

export const initialState = (): BatchVacancyState => ({
    targetPlaceList: [],
});

export const state: BatchVacancyState = initialState();

export const mutations: MutationTree<BatchVacancyState> = {
    setTargetPlaceList(state: BatchVacancyState, payload: { targetPlaceList: BatchManualInputPlace[] }) {
        state.targetPlaceList = payload.targetPlaceList;
    },
    clearTargetPlaceList(state: BatchVacancyState) {
        state.targetPlaceList = [];
    },
};

export const actions: ActionTree<BatchVacancyState, AppState> = {};

export const getters: GetterTree<BatchVacancyState, AppState> = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
