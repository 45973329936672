import Vue, { PluginObject } from "vue";

type CouponDiscountUnit = "yen" | "percent";

const install = () => {
    Vue.filter("discountUnit.ja", (discountUnit: CouponDiscountUnit): string => {
        switch (discountUnit) {
            case "yen":
                return "円";
            case "percent":
                return "%";
        }
    });
};

export default {
    install,
} as PluginObject<void>;
