import { AxiosError } from "axios";
import { ActionTree, Commit, MutationTree, ActionContext, GetterTree } from "vuex";
import { renewSession as callRenewSessionApi } from "@/api/console.adapter.api";
import { AppState } from "@/store";
import { fetchAuthorizedPlaceList, fetchMenuList } from "@/api/console.adapter.api";
import { AuthorizedPlace } from "@/@types/place";
import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";
import { getPromiseState } from "@/utils/promise";
import dayjs from "dayjs";

export type MenuGroup = {
    title: string;
    titleI18n: ConsoleAdapterApi.ResponseI18nObject;
    priority: number;
    items: { path: string; name: string; nameI18n: ConsoleAdapterApi.ResponseI18nObject; iconName: string }[];
};

export type MenuDictionary = Record<string, MenuGroup>;

const AUTHORIZED_PLACE_LIST_LIMIT = 100;

/**
 * fetchの結果はstoreを使うため、リクエストの結果のみを返却する
 */
export type RequestFetchHeaderResult = {
    hasMore: boolean;
    status: number;
};

export interface AuthState {
    menuList: MenuDictionary;
    didMenuListFetched: boolean;
    placeList: AuthorizedPlace[];
    selectingPlace?: AuthorizedPlace;
    sessionRenewingPromise?: Promise<ConsoleAdapterApi.ResponsePostLogin>;
    migrationEndUnixtime?: number;
}

export const initialState = (): AuthState => ({
    menuList: {},
    didMenuListFetched: false,
    placeList: [],
    selectingPlace: undefined,
    sessionRenewingPromise: undefined,
    migrationEndUnixtime: undefined,
});

function isAxiosError(error: unknown): error is AxiosError {
    return typeof error === "object" && error !== null && "isAxiosError" in error;
}

export const authState: AuthState = initialState();

export const getters: GetterTree<AuthState, AppState> = {
    menuGroupList(state: AuthState): MenuGroup[] {
        return Object.values(state.menuList).sort((a, b) => a.priority - b.priority);
    },
    isInMigration(state: AuthState): boolean {
        return state.migrationEndUnixtime !== undefined && state.migrationEndUnixtime > dayjs().unix();
    },
};

export const mutations: MutationTree<AuthState> = {
    setMenuList(state: AuthState, menuList: MenuDictionary): void {
        state.menuList = menuList;
    },
    setDidMenuListFetched(state: AuthState, didMenuListFetched: boolean): void {
        state.didMenuListFetched = didMenuListFetched;
    },
    setPlaceList(state: AuthState, placeList: AuthorizedPlace[]): void {
        state.placeList = placeList;
    },
    setSelectingPlace(state: AuthState, place: AuthorizedPlace): void {
        localStorage.setItem("session/selectingPlace/placeIdHash", place.placeIdHash);
        state.selectingPlace = place;
    },
    setSessionRenewingPromise(state: AuthState, promise: Promise<ConsoleAdapterApi.ResponsePostLogin>) {
        state.sessionRenewingPromise = promise;
    },
    setMigrationEndUnixtime(state: AuthState, migrationEndUnixtime: number) {
        state.migrationEndUnixtime = migrationEndUnixtime;
    },
};

export const actions: ActionTree<AuthState, AppState> = {
    async setMenuList({ commit, state }: { commit: Commit; state: AuthState }): Promise<void> {
        if (state.didMenuListFetched) {
            return;
        }
        const menuList: ConsoleAdapterApi.ResponseGetAuthorizedMenu[] = await fetchMenuList();
        const menuDictionary: MenuDictionary = menuList.reduce(
            (dictionary: MenuDictionary, menu: ConsoleAdapterApi.ResponseGetAuthorizedMenu) => {
                if (dictionary[menu.productType.type] === undefined) {
                    dictionary[menu.productType.type] = {
                        title: menu.productType.text,
                        titleI18n: menu.productType.textI18n,
                        priority: menu.productType.priority,
                        items: [],
                    };
                }
                dictionary[menu.productType.type].items.push({
                    path: menu.path,
                    name: menu.name,
                    nameI18n: menu.nameI18n,
                    iconName: menu.iconName,
                });

                return dictionary;
            },
            {},
        );
        commit("setMenuList", menuDictionary);
        commit("setDidMenuListFetched", true);
    },
    clearMenuList({ commit }: { commit: Commit }): void {
        commit("setMenuList", []);
        commit("setDidMenuListFetched", false);
    },
    renewSession({ commit, state }: { commit: Commit; state: AuthState }): Promise<void> {
        // セッション更新のAPIを同時に複数回呼ばないように制御する
        // control requests for session refresh api to avoid duplication
        return new Promise((resolve) => {
            if (state.sessionRenewingPromise === undefined) {
                commit("setSessionRenewingPromise", callRenewSessionApi());
                return resolve();
            }

            getPromiseState(state.sessionRenewingPromise, (result) => {
                // 直前のAPIリクエストが解決するまでは新しいリクエストを発行しない
                // do not issue new API request before previous request has been resolved
                if (result !== "pending") {
                    commit("setSessionRenewingPromise", callRenewSessionApi());
                }

                return resolve();
            });
        });
    },
    async verifySelectedPlace({ commit }: ActionContext<AuthState, AppState>) {
        const selectingPlaceIdHash: string | null = localStorage.getItem("session/selectingPlace/placeIdHash");
        if (selectingPlaceIdHash === null) {
            const placeList: ConsoleAdapterApi.ResponseGetUserPlaceHeader[] = await fetchAuthorizedPlaceList({});
            commit("setSelectingPlace", placeList[0]);
            return;
        }

        const authorizedPlaceList: ConsoleAdapterApi.ResponseGetUserPlaceHeader[] = await fetchAuthorizedPlaceList({
            placeIdHashList: [selectingPlaceIdHash],
        });
        if (authorizedPlaceList.length !== 1) {
            const placeList: ConsoleAdapterApi.ResponseGetUserPlaceHeader[] = await fetchAuthorizedPlaceList({});
            commit("setSelectingPlace", placeList[0]);
            return;
        }

        commit("setSelectingPlace", {
            placeIdHash: authorizedPlaceList[0].placeIdHash,
            name: authorizedPlaceList[0].name,
        });
        return;
    },
    async fetchHeaderAuthorizedPlaceList(
        { commit }: ActionContext<AuthState, AppState>,
        searchedPlaceName: string,
    ): Promise<RequestFetchHeaderResult> {
        let placeList: ConsoleAdapterApi.ResponseGetUserPlaceHeader[] = [];
        let hasMore = false;
        try {
            placeList = await fetchAuthorizedPlaceList({
                searchPlaceName: searchedPlaceName,
                limit: AUTHORIZED_PLACE_LIST_LIMIT + 1, // fetch limit + 1 to check has more than 100 or not.
            });
            if (placeList.length > AUTHORIZED_PLACE_LIST_LIMIT) {
                hasMore = true;
                placeList.pop();
            }

            commit("setPlaceList", placeList);
        } catch (e) {
            commit("setPlaceList", []);
            if (isAxiosError(e)) {
                return {
                    hasMore: false,
                    status: e?.code ? parseInt(e?.code) : 500,
                };
            }

            return {
                hasMore: false,
                status: 500,
            };
        }

        return {
            hasMore,
            status: 200,
        };
    },
};

export default {
    namespaced: true,
    state: authState,
    getters,
    mutations,
    actions,
};
