export function getPromiseState(
    promise: Promise<unknown>,
    callback: (result: "pending" | "fulfilled" | "rejected") => void,
) {
    // Symbols and RegExps are never content-equal
    const uniqueValue = window["Symbol"] ? Symbol("unique") : /unique/;

    function notifyPendingOrResolved(value: unknown) {
        if (value === uniqueValue) {
            return callback("pending");
        } else {
            return callback("fulfilled");
        }
    }

    function notifyRejected() {
        return callback("rejected");
    }

    const race = [promise, Promise.resolve(uniqueValue)];
    Promise.race(race).then(notifyPendingOrResolved, notifyRejected);
}
