/** Space 座席管理 */

import { AxiosError, AxiosResponse } from "axios";
import $httpC from "./datastore/http_console";

import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";

// 1. Places 一覧の返却
export async function getPlaces(): Promise<ConsoleAdapterApi.UserPlace[]> {
    try {
        return await $httpC
            .get(`/v2/user/places`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// 2. Layouts 一覧の返却
export async function getLayoutsListByPlaceId(placeId: number): Promise<ConsoleAdapterApi.UserPlaceLayout[]> {
    try {
        return await $httpC
            .get(`/v2/user/places/${placeId}/layouts`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// 3. Attributes 一覧の返却
export async function getLayoutAttributesListByLayoutId(
    layoutId: number,
): Promise<ConsoleAdapterApi.LayoutAttribute[]> {
    try {
        return await $httpC
            .get(`/v2/user/layouts/${layoutId}/attributes`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// 4. Space空き状況の選択肢一覧の返却
export async function getSpaceVacancyStatusesBySpaceId(
    spaceId: number,
): Promise<ConsoleAdapterApi.SpaceVacancyStatus[]> {
    try {
        return await $httpC
            .get(`/v2/spaces/${spaceId}/facilities/vacancy/statuses`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// 5. Space Outline 情報一覧の返却
export async function getSpaceOutlineListByLayoutId(layoutId: number): Promise<ConsoleAdapterApi.SpaceOutline[]> {
    try {
        return await $httpC
            .get(`/v2/layouts/${layoutId}/spaces`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// 6. 空き状況と顧客情報の返却 in a loop (５秒更新し)
export async function getSpaceCustomerVacancyData(
    layoutId: number,
    spaceIdList: number[],
): Promise<ConsoleAdapterApi.SpaceCustomerVacancyData[]> {
    try {
        return await $httpC
            .get(`/v2/user/layouts/${layoutId}/spaces/${spaceIdList.join(",")}/customer-vacancy-data?${Date.now()}`)
            .then((response: AxiosResponse) => {
                return response.status === 200 ? response.data : Promise.reject(400);
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}
// 7. 空き状況と顧客情報の更新
export async function updateSpaceCustomerData(
    layoutId: number,
    payload: ConsoleAdapterApi.UpdateSpaceCustomerVacancyData[],
): Promise<number> {
    try {
        const spaceIdList = payload.map((item: ConsoleAdapterApi.UpdateSpaceCustomerVacancyData) => {
            return item.spaceId;
        });

        const requestPayload: ConsoleAdapterApi.UpdateSpaceCustomerVacancyData[] = payload.map(
            (item: ConsoleAdapterApi.UpdateSpaceCustomerVacancyData) => ({
                spaceId: item.spaceId,
                facilityVacancyData: item.facilityVacancyData,
                customerDataList: item.customerDataList,
            }),
        );

        return await $httpC
            .post(`/v2/user/layouts/${layoutId}/spaces/${spaceIdList.join(",")}/customer-vacancy-data`, requestPayload)
            .then((response: AxiosResponse) => {
                return response.status;
            })
            .catch((error: AxiosError) => {
                return typeof error.response === "undefined"
                    ? Promise.reject(500)
                    : Promise.reject(error.response.status);
            });
    } catch (e) {
        return Promise.reject(400);
    }
}

export default {
    getPlaces,
    getLayoutsListByPlaceId,
    getLayoutAttributesListByLayoutId,
    getSpaceVacancyStatusesBySpaceId,
    getSpaceOutlineListByLayoutId,
    getSpaceCustomerVacancyData,
    updateSpaceCustomerData,
};
