import VueI18n from "vue-i18n";
import { locale } from "@/i18n";

export type I18nObject = {
    languageList: { [locale: string]: string };
    fallbackLanguage: string;
};

export function showI18nString(displayContentI18n: I18nObject | undefined, lang: string): string {
    if (displayContentI18n === undefined) return "";
    let lang2 = lang.replace("-", "_");
    if (lang2 === undefined) lang2 = "ja_JP";
    const lang3 = "ja";
    const feedbackLang = displayContentI18n.fallbackLanguage;

    return displayContentI18n.languageList[lang2] === undefined || displayContentI18n.languageList[lang2] === ""
        ? displayContentI18n.languageList[lang3] === undefined
            ? displayContentI18n.languageList[feedbackLang]
            : displayContentI18n.languageList[lang3]
        : displayContentI18n.languageList[lang2];
}

export function reloadHtmlLang() {
    let savedLang: string | null = localStorage.getItem("vacan/console/lang");
    if (savedLang && savedLang === "zh_TW") {
        savedLang = "zh-cmn-Hant";
    } else if (savedLang && savedLang === "zh_CN") {
        savedLang = "zh-cmn-Hans";
    }

    const documentRoot = document.getElementsByTagName("html")[0];
    if (documentRoot) {
        documentRoot.lang = savedLang ? savedLang.replace("_", "-") : "ja-JP";
    }
}

export function setLocale(locale: VueI18n.Locale) {
    localStorage.setItem("vacan/console/lang", locale);
    reloadHtmlLang();
}

export function getLocale() {
    const savedLang: string | null = localStorage.getItem("vacan/console/lang");
    return savedLang !== null ? savedLang : locale;
}

export default { showI18nString, reloadHtmlLang, setLocale, getLocale };
