import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
import jaLocale from "vee-validate/dist/locale/ja";
import enLocale from "vee-validate/dist/locale/en";
import cnLocale from "vee-validate/dist/locale/zh_CN";
import twLocale from "vee-validate/dist/locale/zh_TW";
import I18n from "./i18n";

Validator.localize("ja", jaLocale);
Validator.localize("en", enLocale);
Validator.localize("zh_CN", cnLocale);
Validator.localize("zh_TW", twLocale);

/* eslint-disable @typescript-eslint/no-explicit-any */
const customMessages = {
    _default: (field: string, args: any) => I18n.t("validatorMessage.default", [field, args[0]]) as string,
};
const dictionary = {
    ja: {
        messages: customMessages,
    },
    en: {
        messages: customMessages,
    },
    zh_CN: {
        messages: customMessages,
    },
    zh_TW: {
        messages: customMessages,
    },
};

Vue.use(VeeValidate, { locale: I18n.locale, dictionary });

Validator.extend("required", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.required", [field]) as string;
    },
    validate: (value: any) => value !== undefined && value !== null && value !== "",
});

// 全角文字で何文字か返す。半角は0.5文字としてカウントする
function zenkakuLength(str: string): number {
    let sum = 0;
    [...str].forEach((char) => (sum += char.match(/[ -~]/) ? 0.5 : 1));
    return sum;
}
Validator.extend("max_zenkaku", {
    getMessage: (field: string, args: any) => {
        return I18n.t("validatorMessage.max_zenkaku", [field, parseInt(args[0]), parseInt(args[0]) * 2]) as string;
    },
    validate: (value: string, args: any) => zenkakuLength(value) <= parseInt(args.length),
    paramNames: ["length"],
});

Validator.extend("max_zenkaku_generic", {
    getMessage: (field: string, args: any) => {
        return I18n.t("validatorMessage.max_zenkaku_generic", [parseInt(args[0]), parseInt(args[0]) * 2]) as string;
    },
    validate: (value: string, args: any) => zenkakuLength(value) <= parseInt(args.length),
    paramNames: ["length"],
});

Validator.extend("has_uppercase", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.has_uppercase", [field]) as string;
    },
    validate: (value: any) => /[A-Z]/.test(value),
});
Validator.extend("has_lowercase", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.has_lowercase", [field]) as string;
    },
    validate: (value: any) => /[a-z]/.test(value),
});
Validator.extend("has_number", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.has_number", [field]) as string;
    },
    validate: (value: any) => /[\d]/.test(value),
});
Validator.extend("only_text", {
    getMessage: () => {
        return I18n.t("validatorMessage.only_text") as string;
    },
    //eslint-disable-next-line
    validate: (value: any) => /^[^<>\?/.,'";:\\|\]\[}{=\+\-\_\)\*\(\&\^\%\$\#\@\!\~\`]+$/.test(value),
});
Validator.extend("is_2_bits_number", {
    getMessage: () => {
        return I18n.t("validatorMessage.is_2_bits_number") as string;
    },
    validate: (value: any) => /^[0-9]{1,2}$/.test(value),
});
Validator.extend("time", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.time", [field]) as string;
    },
    validate: (value: any) => /\d{2}:\d{2}(:\d{2})/.test(value),
});
Validator.extend("alphabet_numeric", {
    getMessage: () => {
        return I18n.t("validatorMessage.alphabet_numeric") as string;
    },
    validate: (value: any) => /^[a-zA-Z0-9]*$/.test(value),
});
Validator.extend("latlng", {
    getMessage: () => {
        return I18n.t("validatorMessage.latlng") as string;
    },
    validate: (value: any) => {
        const [lat, lng] = value.split(",").map((value: string) => parseFloat(value));
        if (lat === undefined || isNaN(lat) || lat < -90 || lat > 90) {
            return false;
        }
        if (lng === undefined || isNaN(lng) || lng < -180 || lng > 180) {
            return false;
        }
        return true;
    },
});

Validator.extend("line", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.url", [field]) as string;
    },
    validate: (value: string) => value.startsWith("https://line.me") || value.startsWith("https://lin.ee/"),
});
Validator.extend("facebook", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.url", [field]) as string;
    },
    validate: (value: string) => value.startsWith("https://www.facebook.com"),
});
Validator.extend("instagram", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.url", [field]) as string;
    },
    validate: (value: string) => value.startsWith("https://www.instagram.com"),
});
Validator.extend("twitter", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.url", [field]) as string;
    },
    validate: (value: string) => value.startsWith("https://twitter.com") || value.startsWith("https://x.com"),
});
// irregular schedule page
Validator.extend("time2", {
    getMessage: (field: string) => {
        return I18n.t("validatorMessage.time2", [field]) as string;
    },
    validate: (value: string) => /^^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$$/.test(value),
});
/* eslint-enable @typescript-eslint/no-explicit-any */
