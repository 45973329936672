import { ActionTree, GetterTree, MutationTree } from "vuex";
import { AppState } from "@/store";
import { BatchEvacuationDrillPlace } from "@/@types/launch-batch-evacuation-drill";

export type BatchEvacuationDrillState = {
    targetPlaceList: BatchEvacuationDrillPlace[];
};

export const initialState = (): BatchEvacuationDrillState => ({
    targetPlaceList: [],
});

export const state: BatchEvacuationDrillState = initialState();

export const mutations: MutationTree<BatchEvacuationDrillState> = {
    setTargetPlaceList(state: BatchEvacuationDrillState, payload: { targetPlaceList: BatchEvacuationDrillPlace[] }) {
        state.targetPlaceList = payload.targetPlaceList;
    },
    clearTargetPlaceList(state: BatchEvacuationDrillState) {
        state.targetPlaceList = [];
    },
};

export const actions: ActionTree<BatchEvacuationDrillState, AppState> = {};

export const getters: GetterTree<BatchEvacuationDrillState, AppState> = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
