
import Vue from "vue";
import { envBarText } from "@/lib/env-helper";

export default Vue.extend({
  name: "env-bar-component",
  computed: {
    env(): string {
      return process.env.VUE_APP_MODE || "development";
    },
    envText(): string {
      return envBarText(process.env.VUE_APP_MODE);
    },
  },
});
