import { fetchUserAuthorizedPlaceGroupList } from "@/api/cavi.api";
import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";
import { ActionContext, ActionTree, MutationTree } from "vuex";
import { AppState } from "@/store";

export type PlaceGroup = { placeGroupIdHash: string; placeGroupName: string };

export type CaviState = {
    placeGroupList: PlaceGroup[];
    selectingPlaceGroup?: PlaceGroup;
};

export const initialState = (): CaviState => ({
    placeGroupList: [],
    selectingPlaceGroup: undefined,
});

export const caviState = initialState();

export const mutations: MutationTree<CaviState> = {
    setPlaceGroupList(state: CaviState, placeGroupList: PlaceGroup[]) {
        state.placeGroupList = placeGroupList;
    },
    setSelectingPlaceGroup(state: CaviState, placeGroup: PlaceGroup) {
        localStorage.setItem("session/selectingPlaceGroup/placeGroupIdHash", placeGroup.placeGroupIdHash);
        state.selectingPlaceGroup = placeGroup;
    },
};

export const actions: ActionTree<CaviState, AppState> = {
    async fetchUserAuthorizedPlaceGroupList({ commit }: ActionContext<CaviState, AppState>) {
        const placeGroupList: ConsoleAdapterApi.UserPlaceGroup[] = await fetchUserAuthorizedPlaceGroupList();
        commit("setPlaceGroupList", placeGroupList);
    },
    async verifySelectedPlaceGroup({ commit, state }: ActionContext<CaviState, AppState>) {
        const selectingPlaceGroupIdHash: string | null = localStorage.getItem(
            "session/selectingPlaceGroup/placeGroupIdHash",
        );

        if (selectingPlaceGroupIdHash === null) {
            if (state.selectingPlaceGroup === null) {
                commit("setPlaceGroupList", state.placeGroupList[0]);
            }
            return;
        }

        const matched = state.placeGroupList.find(
            (placeGroup) => placeGroup.placeGroupIdHash === selectingPlaceGroupIdHash,
        );

        commit("setSelectingPlaceGroup", matched ? matched : state.placeGroupList[0]);
    },
};

export default {
    namespaced: true,
    state: caviState,
    mutations,
    actions,
};
