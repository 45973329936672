import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import SpacesMgrModule, { initialState as spaceInitialState } from "@/store/spaces-mgr-module";
import SpacesAdminModule, { initialState as spaceAdminInitialState } from "@/store/spaces-admin-module";
import AuthModule, { AuthState, initialState as authInitialState } from "@/store/auth-module";
import MenuModule, { MenuState, initialState as menuInitialState } from "@/store/menu-module";
import caviModule, { CaviState, initialState as caviInitialState } from "./cavi-module";
import { SpaceState, SpaceAdminState } from "spaces-mgr";
import BatchModule, { BatchState, initialState as batchInitialState } from "@/store/batch-module";

export type AppState = {
    menu: MenuState;
    spaces: SpaceState;
    spacesAdmin: SpaceAdminState;
    auth: AuthState;
    cavi: CaviState;
    batch: BatchState;
};

const initialState: AppState = {
    menu: menuInitialState(),
    spaces: spaceInitialState(),
    spacesAdmin: spaceAdminInitialState(),
    auth: authInitialState(),
    cavi: caviInitialState(),
    batch: batchInitialState(),
};

export default new Vuex.Store<AppState>({
    modules: {
        menu: MenuModule,
        spaces: SpacesMgrModule,
        spacesAdmin: SpacesAdminModule,
        auth: AuthModule,
        cavi: caviModule,
        batch: BatchModule,
    },
    mutations: {
        RESET(state: AppState) {
            Object.keys(state).forEach(key => {
                Object.assign(state[key as keyof AppState], initialState[key as keyof AppState]);
            });
        },
    },
});
