
import Vue from "vue";
import PlaceHeader from "@/components/place/place-header.vue";

export default Vue.extend({
  name: "error-view",
  components: {
    PlaceHeader,
  },
});
