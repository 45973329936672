import { InternalAxiosRequestConfig, AxiosError, AxiosInstance } from "axios";
import router from "@/router";
import store from "@/store";
import { decode } from "jsonwebtoken";
import dayjs from "dayjs";
import { ExpiredAccessTokenError } from "@/api/datastore/expired-access-token.error";

export const authorizationItem = (): string => {
    return `Bearer ${localStorage.getItem("session/tokens/accessToken") || ""}`;
};

export const refreshToken = (): string => {
    return localStorage.getItem("session/tokens/refreshToken") || "";
};

export const haveSession = (): boolean => {
    const token: string | null = localStorage.getItem("session/tokens/accessToken");
    return token !== null && token.length > 0;
};

export const isNotExpiredAccessToken = (): boolean => {
    try {
        const token: string | null = localStorage.getItem("session/tokens/accessToken");
        const jwt: any = decode(token || "");
        return jwt.exp !== undefined && jwt.exp > dayjs().unix();
    } catch {
        return false;
    }
};

export function setAuthorizationHeader(config: InternalAxiosRequestConfig) {
    if (haveSession()) {
        config.headers["Authorization"] = authorizationItem();
    }
    return config;
}

export function retryRequestOnError(http: AxiosInstance) {
    return async (error: AxiosError | ExpiredAccessTokenError) => {
        if (
            (error.response !== undefined && error.response.status === 401) ||
            error.name === "ExpiredAccessTokenError"
        ) {
            if (!haveSession()) {
                window.location.href = "/login";
                return Promise.reject(error);
            }

            await store.dispatch("auth/renewSession");
            const session = await store.state.auth.sessionRenewingPromise;
            if (session === undefined) {
                window.location.href = "/login";
                return Promise.reject(error);
            }

            const originalRequest = error.config as InternalAxiosRequestConfig;
            originalRequest.headers["Authorization"] = `Bearer ${session.accessToken}`;
            return http.request(originalRequest);
        }

        return Promise.reject(error);
    };
}
