import Vue from "vue";
import VueRouter, { RouteConfig, Route, RouteRecord, NavigationGuardNext } from "vue-router";

import { decode } from "jsonwebtoken";
import dayjs from "dayjs";

import ErrorPage from "@/views/error.vue";

import store from "@/store";
import { MenuGroup } from "../store/auth-module";
import { fetchAuthorizationHash } from "@/api/console.adapter.api";
import { ConsoleAdapterApi } from "@vacancorp/console.adapter.api.vacanservice.com";
import { getMigrationStatus } from "@/api/migration/migration-v2.api";

Vue.use(VueRouter);

// prettier-ignore
const routes: Array<RouteConfig> = [
    { path: "/", redirect: "/login" },
    {
        path: "/login",
        component: () => import(/* webpackChunkName: "account" */ "@/views/login/login.vue"),
        children: [
            { path: "", component: () => import(/* webpackChunkName: "account" */ "@/views/login/top.vue") },
            { path: "/signup", component: () => import(/* webpackChunkName: "account" */ "@/views/login/signup.vue") },
            {
                path: "/signin/recovery",
                component: () => import(/* webpackChunkName: "account" */ "@/views/login/recovery.vue"),
            },
        ],
    },
    {
        path: "/launch",
        component: () => import(/* webpackChunkName: "launch" */ "@/views/launch/launch-index-header-slot.vue"),
        children: [
            {
                path: "/",
                component: () => import(/* webpackChunkName: "launch" */ "@/views/launch/launch-index-list.vue"),
            },
            {
                path: "",
                component: () => import(/* webpackChunkName: "launch" */ "@/views/launch/launch-batch-index.vue"),
                children: [
                    //一括空き入力
                    {
                        path: "batch/manual-input",
                        name: "launch-batch-vacancy",
                        component: () =>
                            import(/* webpackChunkName: "launch" */ "@/views/launch/launch-batch-vacancy.vue"),
                    },
                    {
                        path: "batch/manual-input/editing",
                        name: "launch-batch-vacancy-editing",
                        component: () =>
                            import(/* webpackChunkName: "launch" */ "@/views/launch/launch-batch-vacancy-editing.vue"),
                    },
                    //地図への公開/非公開
                    {
                        path: "batch/publish",
                        name: "launch-batch-publish",
                        component: () =>
                            import(/* webpackChunkName: "launch" */ "@/views/launch/launch-batch-publish.vue"),
                    },
                    {
                        path: "batch/publish/editing",
                        name: "launch-batch-publish-editing",
                        component: () =>
                            import(/* webpackChunkName: "launch" */ "@/views/launch/launch-batch-publish-editing.vue"),
                    },
                    //一括臨時休業＆営業
                    {
                        path: "batch/irregular-schedules",
                        name: "launch-batch-irregular-schedules",
                        component: () =>
                            import(
                                /* webpackChunkName: "launch" */ "@/views/launch/launch-batch-irregular-schedules.vue"
                            ),
                    },
                    {
                        path: "batch/irregular-schedules/register",
                        name: "launch-batch-irregular-schedules-register",
                        component: () =>
                            import(
                                /* webpackChunkName: "launch" */ "@/views/launch/launch-batch-irregular-schedules-register.vue"
                            ),
                    },
                    {
                        path: "batch/irregular-schedules/delete",
                        name: "launch-batch-irregular-schedules-delete",
                        component: () =>
                            import(
                                /* webpackChunkName: "launch" */ "@/views/launch/launch-batch-irregular-schedules-delete.vue"
                            ),
                    },
                    //避難訓練表示
                    {
                        path: "batch/evacuation-drill",
                        name: "launch-batch-evacuation-drill",
                        component: () =>
                            import(/* webpackChunkName: "launch" */ "@/views/launch/launch-batch-evacuation-drill.vue"),
                    },
                    {
                        path: "batch/evacuation-drill/register",
                        name: "launch-batch-evacuation-drill-edit",
                        component: () =>
                            import(
                                /* webpackChunkName: "launch" */ "@/views/launch/launch-batch-evacuation-drill-edit.vue"
                            ),
                    },
                ],
            },
            {
                path: "",
                component: () => import("@/views/launch/launch-batch-analytics/launch-batch-analytics-index.vue"),
                children: [
                    {
                        path: "batch-analytics/vacancy",
                        name: "launch-batch-analytics-vacancy",
                        component: () =>
                            import("@/views/launch/launch-batch-analytics/launch-batch-analytics-vacancy.vue"),
                    },
                    {
                        path: "batch-analytics/pv",
                        name: "launch-batch-analytics-pv",
                        component: () => import("@/views/launch/launch-batch-analytics/launch-batch-analytics-pv.vue"),
                    },
                ],
            },
        ],
        meta: { requireLogin: ["login"] },
    },
    {
        path: "/places",
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        props: (route: Route) => {
            return route.path.includes("/places/analytics/ais-camera") ||
                route.path.includes("/places/spaces") ||
                (route.path.includes("/places/vacancy/") && !route.path.includes("/places/vacancy/unavailable-count"))
                ? {
                      showPlacePullDownMenu: false,
                  }
                : {
                      showPlacePullDownMenu: true,
                  };
        },
        meta: { requireLogin: ["login"] },
        children: [
            /** @remarks AIS camera */
            // basic plan
            {
                path: "dashboard",
                name: "console/top",
                component: () => import(/* webpackChunkName: "dashboard" */ "@/views/place/dashboard.vue"),
            },
            // advanced plan
            {
                path: "analytics/ais-camera",
                component: () => import(/* webpackChunkName: "analytics" */ "@/views/place/analytics/ais-camera.vue"),
            },
            {
                path: "vacancy/unavailable-count",
                component: () =>
                    import(
                        /* webpackChunkName: "analysis" */ "@/views/place/vacancy-history/unavailable-count-history.vue"
                    ),
            },
            {
                path: "vacancy/waiting-seconds",
                component: () =>
                    import(
                        /* webpackChunkName: "analysis" */ "@/views/place/vacancy-history/waiting-seconds-history.vue"
                    ),
            },
            {
                path: "vacancy/waiting-count",
                component: () =>
                    import(
                        /* webpackChunkName: "analysis" */ "@/views/place/vacancy-history/waiting-count-history.vue"
                    ),
            },
            {
                path: "vacancy/csv-download",
                component: () =>
                    import(/* webpackChunkName: "analysis" */ "@/views/place/vacancy-history/csv-download.vue"),
            },
            {
                path: "vacancy/distribution",
                component: () =>
                    import(
                        /* webpackChunkName: "analysis" */ "@/views/place/vacancy-history/waiting-seconds-distribution.vue"
                    ),
            },
            // ANA-VIP 2
            {
                path: "spaces",
                component: () => import(/* webpackChunkName: "space" */ "@/views/spaces-mgr/top-view.vue"),
                children: [
                    {
                        path: "/",
                        component: () => import(/* webpackChunkName: "space" */ "@/views/spaces-mgr/space-view.vue"),
                    },
                    {
                        path: "admin",
                        component: () => import(/* webpackChunkName: "space" */ "@/views/spaces-mgr/admin-view.vue"),
                    },
                ],
            },
        ],
    },
    {
        path: "/places",
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        props: {
            showPlacePullDownMenu: true,
        },
        meta: { requireLogin: ["login"] },
        children: [
            {
                path: "manual-input",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/manual-input.vue"),
            },
            { path: "info", component: () => import(/* webpackChunkName: "base" */ "@/views/place/info.vue") },
            {
                path: "schedules",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/schedules.vue"),
            },
            {
                path: "irregular-schedules",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/irregular-schedules.vue"),
            },
            {
                path: "gender-settings",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/gender-settings.vue"),
            },
            {
                path: "categories",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/categories.vue"),
            },
            {
                path: "attachments/equipments",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/attachments/equipments.vue"),
            },
            {
                path: "description/medias",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/description/medias.vue"),
            },
            {
                path: "default-vacancy-settings",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/default-vacancy-settings.vue"),
            },
            {
                path: "facility-settings",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/facility-settings.vue"),
            },
            {
                path: "facility-rate-settings",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/facility-rate-settings.vue"),
            },
            {
                path: "battery-level",
                component: () => import(/* webpackChunkName: "base" */ "@/views/place/battery-level.vue"),
            },

            {
                path: "notifications",
                component: () => import(/* webpackChunkName: "portal" */ "@/views/place/global-announcement.vue"),
            },
            {
                path: "instructions",
                component: () => import(/* webpackChunkName: "portal" */ "@/views/place/instructions.vue"),
            },
            { path: "images", component: () => import(/* webpackChunkName: "portal" */ "@/views/place/images.vue") },
            { path: "coupons", component: () => import(/* webpackChunkName: "portal" */ "@/views/place/coupons.vue") },
            { path: "soldout", component: () => import(/* webpackChunkName: "portal" */ "@/views/place/soldout.vue") },
            {
                path: "iot-button-settings",
                component: () => import(/* webpackChunkName: "portal" */ "@/views/place/iot-button-settings.vue"),
            },
            {
                path: "attachments/toilets",
                component: () => import(/* webpackChunkName: "portal" */ "@/views/place/attachments/toilets.vue"),
            },
            {
                path: "description/accesses",
                component: () => import(/* webpackChunkName: "portal" */ "@/views/place/description/accesses.vue"),
            },
            {
                path: "description/remarks",
                component: () => import(/* webpackChunkName: "portal" */ "@/views/place/description/remarks.vue"),
            },
            {
                path: "description/payments",
                component: () => import(/* webpackChunkName: "portal" */ "@/views/place/description/payments.vue"),
            },
            {
                path: "description/menus",
                component: () => import(/* webpackChunkName: "portal" */ "@/views/place/description/menus.vue"),
            },
            {
                path: "csv-download",
                component: () => import(/* webpackChunkName: "dashboard" */ "@/views/place/vacancy-csv-download.vue"),
            },
            {
                path: "number-plate-download",
                component: () =>
                    import(/* webpackChunkName: "dashboard" */ "@/views/place/number-plate/number-plate-download.vue"),
            },
            {
                path: "analytics/pages",
                component: () => import(/* webpackChunkName: "dashboard" */ "@/views/place/analytics/pages.vue"),
            },
            {
                path: "analytics/maps",
                component: () => import(/* webpackChunkName: "dashboard" */ "@/views/place/analytics/maps.vue"),
            },
            {
                path: "message",
                component: () => import(/* webpackChunkName: "dashboard" */ "@/views/place/message.vue"),
            },
            {
                path: "custom-link",
                component: () => import(/* webpackChunkName: "dashboard" */ "@/views/place/custom-link.vue"),
            },
        ],
    },
    {
        path: "/pages",
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        props: {
            showPlacePullDownMenu: true,
        },
        meta: { requireLogin: ["login"] },
        children: [
            {
                path: "images",
                component: () => import(/* webpackChunkName: "pages" */ "@/views/pages/images.vue"),
            },
        ],
    },
    {
        path: "/noline",
        props: {
            showPlacePullDownMenu: true,
        },
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        meta: { requireLogin: ["login"] },
        children: [
            {
                path: "line-management",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/management.vue"),
            },
            { path: "images", component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/images.vue") },
            {
                path: "idle-images",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/idle-images.vue"),
            },
            {
                path: "queue-limit",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/queue-limit.vue"),
            },
            {
                path: "notification",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/notification.vue"),
            },
            {
                path: "timeout",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/calling-timeout.vue"),
            },
            {
                path: "announcements",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/announcements.vue"),
            },
            {
                path: "message",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/message.vue"),
            },
            {
                path: "auto-call",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/auto-call.vue"),
            },
            {
                path: "online-reception",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/online-reception.vue"),
            },
            {
                path: "sms-usage",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/sms-usage.vue"),
            },
            {
                path: "seat-customization",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/qticket-question.vue"),
            },
            {
                path: "check-in",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/check-in.vue"),
            },
            {
                path: "duplication-check",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/duplication-check.vue"),
            },
            {
                path: "receipt-description",
                component: () => import(/* webpackChunkName: "noline" */ "@/views/noline/receipt-description.vue"),
            },
        ],
    },
    {
        path: "/cavi",
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        props: (route: Route) => {
            return route.path === "/cavi/images"
                ? {
                      showPlacePullDownMenu: true,
                      showPlaceGroupMenu: false,
                  }
                : {
                      showPlacePullDownMenu: false,
                      showPlaceGroupMenu: true,
                  };
        },
        meta: { requireLogin: ["login"] },
        children: [
            {
                path: "link-management",
                name: "cavi/link-management",
                component: () => import(/* webpackChunkName: "cavi" */ "@/views/cavi/link-management.vue"),
            },
            {
                path: "cavi-list",
                name: "cavi/qr-list",
                component: () => import(/* webpackChunkName: "cavi" */ "@/views/cavi/qr-list.vue"),
            },
            {
                path: "cavi-map-management",
                name: "cavi/map-management",
                component: () => import(/* webpackChunkName: "cavi" */ "@/views/cavi/map-management.vue"),
            },
            {
                path: "map-upload",
                component: () => import(/* webpackChunkName: "cavi" */ "@/views/cavi/map-upload.vue"),
            },
            {
                path: "images",
                component: () => import(/* webpackChunkName: "cavi" */ "@/views/cavi/images.vue"),
            },
        ],
    },
    {
        path: "/signage",
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        props: {
            showPlacePullDownMenu: true,
        },
        meta: { requireLogin: ["login"] },
        children: [
            {
                path: "images",
                component: () => import(/* webpackChunkName: "signage" */ "@/views/signage/images.vue"),
            },
        ],
    },
    {
        path: "/api",
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        props: {
            showPlacePullDownMenu: true,
        },
        meta: { requireLogin: ["login"] },
        children: [{ path: "images", component: () => import(/* webpackChunkName: "api" */ "@/views/api/images.vue") }],
    },
    {
        path: "/vacancy-optional",
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        props: {
            showPlacePullDownMenu: true,
        },
        meta: { requireLogin: ["login"] },
        children: [
            {
                path: "inside-count",
                component: () =>
                    import(/* webpackChunkName: "vacancy-optional" */ "@/views/vacancy-optional/inside-count.vue"),
            },
        ],
    },
    {
        path: "/sensor-setting",
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        props: {
            showPlacePullDownMenu: true,
        },
        meta: { requireLogin: ["login"] },
        children: [
            {
                path: "inside-count-override",
                component: () => import("@/views/sensor-setting/inside-count-override.vue"),
            },
            {
                path: "inside-count-reset",
                component: () => import("@/views/sensor-setting/inside-count-reset.vue"),
            },
        ],
    },
    {
        path: "/autokeep",
        component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/index-layout.vue"),
        props: {
            showPlacePullDownMenu: true,
        },
        meta: { requireLogin: ["login"] },
        children: [
            {
                path: "store",
                component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/store.vue"),
            },
            {
                path: "images",
                component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/images.vue"),
            },
            {
                path: "sales",
                component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/sales.vue"),
            },
            {
                path: "claims",
                component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/claims.vue"),
            },
            {
                path: "announcement",
                component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/announcement.vue"),
            },
            {
                path: "suspension",
                component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/suspension.vue"),
            },
            {
                path: "questionnaire",
                component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/questionnaire.vue"),
            },
            {
                path: "download",
                component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/download.vue"),
            },
            {
                path: "history",
                component: () => import(/* webpackChunkName: "autokeep" */ "@/views/autokeep/history.vue"),
            },
        ],
    },
    {
        path: "/patokids",
        component: () => import(/* webpackChunkName: "patokids" */ "@/views/place/index-new-layout.vue"),
        props: {
            showPlacePullDownMenu: true,
        },
        meta: { requireLogin: ["login"] },
        children: [
            {
                path: "/",
                component: () => import(/* webpackChunkName: "patokids" */ "@/views/patokids/index.vue"),
            },
        ],
    },
    {
        path: "/places/activity",
        component: () => import(/* webpackChunkName: "newLayout" */ "@/views/place/index-new-layout.vue"),
        props: {
            showPlacePullDownMenu: true,
        },
        meta: { requireLogin: ["login"] },
        children: [
            {
                name: "activity/list",
                path: "list",
                component: () => import(/* webpackChunkName: "activity" */ "@/views/place/activity/activity-list.vue"),
            },
            {
                name: "activity/input",
                path: "input",
                component: () => import(/* webpackChunkName: "activity" */ "@/views/place/activity/activity-input.vue"),
            },
            {
                name: "activity/history",
                path: "history",
                component: () =>
                    import(/* webpackChunkName: "activity" */ "@/views/place/activity/activity-history.vue"),
            },
        ],
    },
    { path: "/notfound", component: ErrorPage },
    {
        path: "*", beforeEnter: (to, from, next) => {
            // for IE compatibility route
            if (to.path.startsWith("/legacy")) {
                console.log("detecting legacy access");
                return next(to.path.replace("/legacy", ""));
            }
            return next("/notfound");
        }
    },
];

const router: VueRouter = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

const isAccessTokenValid = (): boolean => {
    const accessToken: string = localStorage.getItem("session/tokens/accessToken") || "";
    if (accessToken.length === 0) {
        return false;
    }
    /* eslint-disable-next-line */
    const jwt: any = decode(accessToken);
    return jwt.exp > dayjs().unix();
};

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
    const needAuthentication: boolean = to.matched.some(
        (record: RouteRecord) => record.meta.requireLogin !== undefined && record.meta.requireLogin.length > 0,
    );
    if (!needAuthentication) {
        return next();
    }

    if (!localStorage.getItem("session/tokens/accessToken")) {
        window.location.href = "/login";
        return;
    }

    if (!isAccessTokenValid()) {
        // tokenをrefresh
        await store.dispatch(`auth/renewSession`);
        const session = await store.state.auth.sessionRenewingPromise;

        if (session === undefined) {
            window.location.href = "/login";
            return;
        }
    }

    const migrationData = await getMigrationStatus();
    if (migrationData.forceUseConsoleV2) {
        const baseUrl = process.env.VUE_APP_CONSOLE_V2_BASE_URL || "https://console.vacan.com";
        window.location.href = `${baseUrl}/organizations?migrateFrom=v1`;
        return;
    }

    if (migrationData.isInMigrationPeriod()) {
        store.commit("auth/setMigrationEndUnixtime", migrationData.migrationEndUnixtime);
    }

    if (to.path === "/launch") {
        return next();
    }

    if (!store.state.auth.didMenuListFetched) {
        try {
            await store.dispatch("auth/setMenuList");
        } catch (error) {
            next(error instanceof Error ? error : new Error());
        }
    }

    // let it go section
    if (to.path === "/places/notifications" || to.path === "/places/instructions" || to.path === "/places/dashboard") {
        return next();
    }

    // launch側で独自にガードをかけているようなので、ここでは無視する
    if (to.path.startsWith("/launch")) {
        return next();
    }

    const canNext: boolean = Object.values(store.state.auth.menuList).some((menuGroup: MenuGroup) =>
        menuGroup.items.some((menuItem) => menuItem.path === to.path),
    );

    if (!canNext && to.name !== "console/top") {
        return next("/places/dashboard");
    }

    return next();
});

router.afterEach(async (to: Route, from: Route) => {
    if (to.path === "/login" || to.path === "/signup" || to.path === "/signin/recovery") {
        return;
    }
    const menuListHash: ConsoleAdapterApi.ResponseConsoleAuthorization = await fetchAuthorizationHash();
    const localMenuListHash = localStorage.getItem("menuListHash");
    if (menuListHash.consoleAuthorizationHash === localMenuListHash) {
        return;
    }
    localStorage.setItem("menuListHash", menuListHash.consoleAuthorizationHash);

    if (from.path === "/login") {
        return;
    }
    location.reload();
});

export default router;
